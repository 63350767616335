import { Api } from "api";

export const getChats = async (search: URLSearchParams, page?: number) => {
  let url = "/chat/v1/channels/admin";
  let params = search ? `?${search}` : "";
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`${url}${params}`, {
    params: { page },
    headers,
  });
  return res.data;
};

export const getChatById = async (id: string | undefined) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/chat/v1/channels/admin/${id}`, { headers });
  return res.data;
};

export const deleteChat = async (id: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.delete(`/chat/v1/channels/admin/${id}`, { headers });
  return res.data;
};

export const getChatMembers = async (id: string | undefined) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/chat/v1/channels/admin/${id}/members`, {
    headers,
  });
  return res.data;
};

export const getChatPosts = async (id: string | undefined) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/chat/v1/channels/admin/${id}/posts`, { headers });
  return res.data;
};

export const getChatFiles = async (channelId: string | undefined) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/files/v1/${channelId}`, { headers });
  return res.data;
};
