import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSharedState } from "sharedStateProvider";
import { UserSession, UserType, emptyUser } from "types/user-types";
import RemoveNonNumericCharacters from "components/helpers/remove-non-numeric";
import PageLayout from "components/page-layout";
import {
  IconAbc,
  IconBrandAndroid,
  IconBrandApple,
  IconBriefcase,
  IconBuildingFactory2,
  IconCalendarClock,
  IconCalendarPlus,
  IconCalendarRepeat,
  IconCalendarUser,
  IconDevicesQuestion,
  IconGenderBigender,
  IconId,
  IconLock,
  IconLockOpen,
  IconPhone,
  IconUser,
  IconUserEdit,
  IconUserQuestion,
  IconUsersGroup,
  IconUserX,
} from "@tabler/icons-react";
import NewUser from "components/modals/new-user";
import DangerModal from "components/modals/danger";
import { changeUserStatus, deleteAdmin, deleteUser, getUsersByIds, getUserSessions } from "api/users";

type dangerModalType = "block" | "unblock" | "delete" | "";

export default function UserProfile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isSuperAdmin, notify } = useSharedState();

  const [user, setUser] = useState<UserType>(emptyUser);
  const [userLength, setUserLength] = useState<number>(0);
  const [userSessions, setUserSessions] = useState<UserSession[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [deletingUserId, setDeletingUserId] = useState<string>("");
  const [dangerType, setDangerType] = useState<dangerModalType>("");
  const [dangerDescription, setDangerDescription] = useState<string>("");
  const blockUserText = `Вы действительно хотите заблокировать ${false ? "администратора" : "пользователя"}`;
  const unblockUserText = `Вы действительно хотите разблокировать ${false ? "администратора" : "пользователя"}`;
  const deleteUserText = `Вы действительно хотите удалить ${false ? "администратора" : "пользователя"}`;

  const initProfile = async () => {
    setIsLoading(true);
    Promise.all([await getUser(), await getSessions()]);
    setIsLoading(false);
  };

  const getUser = async () => {
    id &&
      (await getUsersByIds([id])
        .then((res: UserType[]) => {
          if (res[0].first_name === "Deleted") {
            notify("Пользователь удалён", "danger");
            navigate("/", { replace: true });
          } else {
            setUser(res[0]);
            setUserLength(1);
          }
        })
        .catch((error: any) => {
          let err = error.response.data.error;
          notify(err, "danger", "Ошибка при загрузке пользователя");
          navigate("/", { replace: true });
        }));
  };

  const getSessions = async () => {
    id &&
      (await getUserSessions(id)
        .then((res: UserSession[]) => {
          setUserSessions(res);
        })
        .catch());
  };

  const onDangerModal = (user: UserType, type: dangerModalType) => {
    setDeletingUserId(user.id || "");
    let userName = `${user.first_name} ${user.last_name}`;

    switch (type) {
      case "block":
        setDangerType("block");
        setDangerDescription(blockUserText + ` "${userName}"?`);
        break;
      case "unblock":
        setDangerType("unblock");
        setDangerDescription(unblockUserText + ` "${userName}"?`);
        break;
      case "delete":
        setDangerType("delete");
        setDangerDescription(deleteUserText + ` "${userName}"?`);
        break;
      default:
        break;
    }
  };

  const confirmDanger = async () => {
    switch (dangerType) {
      case "block":
        changeUserStatus(deletingUserId, "inactive")
          .then(() => {
            notify("Пользователь заблокирован", "success");
            initProfile();
          })
          .catch((error: any) => {
            let err = error.response.data.error;
            notify(err, "danger", "Ошибка при блокировке пользователя");
          });
        break;
      case "unblock":
        changeUserStatus(deletingUserId, "active")
          .then(() => {
            notify("Пользователь разблокирован", "success");
            initProfile();
          })
          .catch((error: any) => {
            let err = error.response.data.error;
            notify(err, "danger", "Ошибка при разблокировке пользователя");
          });
        break;
      case "delete":
        (false ? deleteAdmin : deleteUser)(deletingUserId)
          .then(() => {
            notify("Пользователь удалён", "success");
            initProfile();
          })
          .catch((error: any) => {
            let err = error.response.data.error;
            notify(err, "danger", "Ошибка при удалении пользователя");
          });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    initProfile();
  }, []);

  const userHeader = (
    <div className="page-header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-auto">
            <span
              className="avatar avatar-lg rounded"
              style={
                user.avatar
                  ? {
                      backgroundImage: `url(${user.avatar})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                    }
                  : undefined
              }
            >
              {!user.avatar && <IconUser />}
            </span>
          </div>
          <div className="col">
            <h1 className="fw-bold mb-1">
              {user.first_name} {user.last_name}
            </h1>
            <div className="list-inline list-inline-dots text-secondary">
              <div
                className={`list-inline-item ${
                  user.status === "active" ? "text-success" : user.status === "inactive" ? "text-danger" : "text-info"
                }`}
              >
                {user.status}
              </div>
              <div className="list-inline-item">
                {user.phone ? `+${RemoveNonNumericCharacters(user.phone)}` : "Нет номера"}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-auto mt-3 mt-lg-0">
            <div className="btn-list flex-row flex-md-column flex-lg-row">
              <button className="btn text-info" data-bs-toggle="modal" data-bs-target="#modal-new-user">
                <IconUserEdit className="icon" />
                Изменить
              </button>
              <button
                className={`btn ${user.status === "inactive" ? "text-success" : "text-danger"} `}
                data-bs-toggle="modal"
                data-bs-target="#modal-danger"
                onClick={() => onDangerModal(user, user.status === "inactive" ? "unblock" : "block")}
              >
                {user.status === "inactive" ? <IconLockOpen className="icon" /> : <IconLock className="icon" />}
                {user.status === "inactive" ? "Разблокировать" : "Заблокировать"}
              </button>
              {isSuperAdmin && (
                <button
                  className="btn text-danger"
                  onClick={() => onDangerModal(user, "delete")}
                  data-bs-toggle="modal"
                  data-bs-target="#modal-danger"
                >
                  <IconUserX className="icon" />
                  Удалить
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const userProfile = (
    <div className="row g-3">
      <div className="col">
        <div className="row row-cards">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="card-title">Информация о пользователе</div>
                <div className="mb-2">
                  <IconUser className="icon me-2 text-secondary" />
                  ID: <strong>{user.id || "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconUser className="icon me-2 text-secondary" />
                  Aitu Passport ID: <strong>{user.ap_id || "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconAbc className="icon me-2 text-secondary" />
                  Имя: <strong>{user.first_name || "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconAbc className="icon me-2 text-secondary" />
                  Фамилия: <strong>{user.last_name || "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconAbc className="icon me-2 text-secondary" />
                  Отчество: <strong>{user.middle_name || "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconGenderBigender className="icon me-2 text-secondary" />
                  Пол:{" "}
                  <strong>{user.gender === "MALE" ? "Мужской" : user.gender === "FEMALE" ? "Женский" : "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconCalendarUser className="icon me-2 text-secondary" />
                  Дата рождения: <strong>{`${new Date(user.birth_date).toLocaleDateString()}` || "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconId className="icon me-2 text-secondary" />
                  ИИН: <strong>{user.iin || "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconPhone className="icon me-2 text-secondary" />
                  Номер телефона:{" "}
                  <strong>{user.phone ? `+${RemoveNonNumericCharacters(user.phone)}` : "Нет номера"}</strong>
                </div>
                <div className="mb-2">
                  <IconUserQuestion className="icon me-2 text-secondary" />
                  Тип пользователя: <strong>{user.user_type || "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconBuildingFactory2 className="icon me-2 text-secondary" />
                  Предприятие: <strong>{user.enterprise || "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconUsersGroup className="icon me-2 text-secondary" />
                  Подразделение: <strong>{user.org_unit || "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconBriefcase className="icon me-2 text-secondary" />
                  Должность: <strong>{user.position || "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconCalendarPlus className="icon me-2 text-secondary" />
                  Создан: <strong>{`${new Date(user.created_at).toLocaleString()}` || "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconCalendarRepeat className="icon me-2 text-secondary" />
                  Изменён: <strong>{`${new Date(user.updated_at).toLocaleString()}` || "-"}</strong>
                </div>
                <div className="mb-2">
                  <IconCalendarClock className="icon me-2 text-secondary" />
                  Последняя активность:{" "}
                  <strong>
                    {user.last_logged_in !== "0001-01-01T00:00:00Z"
                      ? `${new Date(user.last_logged_in || "").toLocaleString()}`
                      : "Неизвестно"}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <h3>Сессии пользователя</h3>
        {userSessions.length ? (
          <ul
            className="timeline"
            style={window.innerWidth >= 992 ? { overflowY: "scroll", height: "calc(100vh - 216px)" } : undefined}
          >
            {userSessions.map((session, index) => (
              <li className="timeline-event" key={index}>
                <div
                  className={`timeline-event-icon ${
                    session.device.os === "android"
                      ? "bg-green-lt border border-green"
                      : session.device.os === "ios"
                      ? "bg-white border border-black"
                      : "bg-primary-lt border border-primary"
                  }`}
                >
                  {session.device.os === "android" ? (
                    <IconBrandAndroid className="icon" />
                  ) : session.device.os === "ios" ? (
                    <IconBrandApple className="icon" />
                  ) : (
                    <IconDevicesQuestion className="icon" />
                  )}
                </div>
                <div className="card timeline-event-card">
                  <div className="card-body">
                    <div className="mb-2">
                      ID сессии: <strong>{session.id || "-"}</strong>
                    </div>
                    <div className="mb-2">
                      ID устройства: <strong>{session.device.id || "-"}</strong>
                    </div>
                    <div className="mb-2">
                      ОС устройства: <strong>{session.device.os || "-"}</strong>
                    </div>
                    <div className="mb-2">
                      Название устройства: <strong>{session.device.name || "-"}</strong>
                    </div>
                    <div className="mb-2">
                      Создана: <strong>{`${new Date(session.created_at).toLocaleString()}` || "-"}</strong>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="card">
            <div className="card-body">Список сессий пуст</div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <PageLayout
        children={userProfile}
        isLoading={isLoading}
        filters={false}
        search={false}
        customHeader={userHeader}
        dataLength={userLength}
        dateRange={false}
      />
      <NewUser userData={user} updateUsers={initProfile} />
      <DangerModal dangerDescription={dangerDescription} confirm={confirmDanger} />
    </>
  );
}
