import { Api } from "api";

export const getBridgeMethods = async (page?: number) => {
  let url = "/mini_apps/v1/admin/bridge_methods";
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`${url}`, { params: { page }, headers });
  return res.data;
};

export const createBridgeMethod = async (payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.post("/mini_apps/v1/admin/bridge_methods", payload, {
    headers,
  });
  return res.data;
};

export const editBridgeMethod = async (name: string, payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.put(
    `/mini_apps/v1/admin/bridge_methods/${name}`,
    payload,
    { headers }
  );
  return res.data;
};

export const deleteBridgeMethod = async (name: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.delete(`/mini_apps/v1/admin/bridge_methods/${name}`, {
    headers,
  });
  return res.data;
};
