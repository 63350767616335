import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSharedState } from "sharedStateProvider";
import { UserType, UsersResponseType, emptyUser, userFiltersList } from "types/user-types";
import { emptyPagination } from "types/pagination-types";
import RemoveNonNumericCharacters from "components/helpers/remove-non-numeric";
import PageLayout from "components/page-layout";
import Table, { tableHeader } from "components/tables/table";
import { avatarWithTwoLines, oneLine, twoLines, mutedLine } from "components/tables/table-parts";
import NewUser from "components/modals/new-user";
import DangerModal from "components/modals/danger";
import NewAdmin from "components/modals/new-admin";
import { changeUserStatus, deleteUser, deleteAdmin, getUsers, getAdmins } from "api/users";
import ActionButton from "components/tables/action-btn";
import { IconLock, IconLockOpen, IconUser, IconUserEdit, IconUserX } from "@tabler/icons-react";

type dangerModalType = "block" | "unblock" | "delete" | "";

export default function UsersList() {
  const location = useLocation();
  const navigate = useNavigate();

  const { isSuperAdmin, notify } = useSharedState();

  const isAdminPath = useMemo(() => location.pathname === "/admins", [location.pathname]);

  const [users, setUsers] = useState<UsersResponseType>(emptyPagination);
  const [firstEntry, setFirstEntry] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());

  const updateFirstEntry = (value: number) => {
    setFirstEntry(value);
  };

  const [editingUser, setEditingUser] = useState<UserType>(emptyUser);

  const [deletingUserId, setDeletingUserId] = useState<string>("");
  const [dangerType, setDangerType] = useState<dangerModalType>("");
  const [dangerDescription, setDangerDescription] = useState<string>("");
  const blockUserText = `Вы действительно хотите заблокировать ${isAdminPath ? "администратора" : "пользователя"}`;
  const unblockUserText = `Вы действительно хотите разблокировать ${isAdminPath ? "администратора" : "пользователя"}`;
  const deleteUserText = `Вы действительно хотите удалить ${isAdminPath ? "администратора" : "пользователя"}`;

  const getAndSetUsers = async (page?: number) => {
    setIsLoading(true);
    setUsers(emptyPagination);

    switch (isAdminPath) {
      case true:
        getAdmins(searchParams)
          .then((res: UserType[]) => {
            setUsers({
              data: res,
              page: 1,
              size: 25,
              sort: "",
              total: res.length,
            });
            setIsLoading(false);
            window.scrollTo(0, 0);
          })
          .catch((error: any) => {
            let err = error.response.data.error;
            notify(err, "danger", "Ошибка при загрузке администраторов");
          });
        break;

      default:
        getUsers(searchParams, page)
          .then((res: UsersResponseType) => {
            setUsers(res);
            setIsLoading(false);
            window.scrollTo(0, 0);
          })
          .catch((error: any) => {
            let err = error.response.data.error;
            notify(err, "danger", "Ошибка при загрузке пользователей");
          });
        break;
    }
  };

  const editUserData = (user: UserType) => {
    setEditingUser(user);
  };

  const onDangerModal = (user: UserType, type: dangerModalType) => {
    setDeletingUserId(user.id || "");
    let userName = `${user.first_name} ${user.last_name}`;

    switch (type) {
      case "block":
        setDangerType("block");
        setDangerDescription(blockUserText + ` "${userName}"?`);
        break;
      case "unblock":
        setDangerType("unblock");
        setDangerDescription(unblockUserText + ` "${userName}"?`);
        break;
      case "delete":
        setDangerType("delete");
        setDangerDescription(deleteUserText + ` "${userName}"?`);
        break;
      default:
        break;
    }
  };

  const confirmDanger = async () => {
    switch (dangerType) {
      case "block":
        changeUserStatus(deletingUserId, "inactive")
          .then(() => {
            notify("Пользователь заблокирован", "success");
            getAndSetUsers();
          })
          .catch((error: any) => {
            let err = error.response.data.error;
            notify(err, "danger", "Ошибка при блокировке пользователя");
          });
        break;
      case "unblock":
        changeUserStatus(deletingUserId, "active")
          .then(() => {
            notify("Пользователь разблокирован", "success");
            getAndSetUsers();
          })
          .catch((error: any) => {
            let err = error.response.data.error;
            notify(err, "danger", "Ошибка при разблокировке пользователя");
          });
        break;
      case "delete":
        (isAdminPath ? deleteAdmin : deleteUser)(deletingUserId)
          .then(() => {
            notify("Пользователь удалён", "success");
            getAndSetUsers();
          })
          .catch((error: any) => {
            let err = error.response.data.error;
            notify(err, "danger", "Ошибка при удалении пользователя");
          });
        break;
      default:
        break;
    }
  };

  const appendParam = (key: string, value: string) => {
    if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  };

  const clearFilters = async (param?: string) => {
    if (param) {
      param && searchParams.delete(param);
    } else {
      searchParams.delete("type");
      searchParams.delete("status");
      searchParams.delete("search");
    }
  };

  useEffect(() => {
    getAndSetUsers();
    clearFilters();
  }, [isAdminPath]);

  const usersTableHeaders: tableHeader[] = [
    { header_text: "№", narrow: true, wrap: false },
    { header_text: "Пользователь", narrow: false, wrap: false },
    { header_text: "Предприятие, подразделение", narrow: false, wrap: true },
    { header_text: "Должность", narrow: false, wrap: false },
    { header_text: "Тип", narrow: false, wrap: false },
    { header_text: "Статус", narrow: false, wrap: false },
    { header_text: "Создан", narrow: false, wrap: false },
    { header_text: "Изменён", narrow: false, wrap: false },
    { header_text: "", narrow: true, wrap: false },
  ];

  const usersList = (
    <>
      {users.data.map((user, index) => (
        <tr key={index}>
          <td>{oneLine((firstEntry + index).toString())}</td>
          <td>
            {avatarWithTwoLines(
              user.avatar,
              "user",
              `${user.first_name} ${user.last_name}`,
              user.phone ? `+${RemoveNonNumericCharacters(user.phone)}` : "Нет номера"
            )}
          </td>
          <td>{twoLines(`${user.enterprise || "-"}`, `${user.org_unit || "-"}`)}</td>
          <td>{oneLine(`${user.position || "-"}`)}</td>
          <td>{mutedLine(`${user.user_type || "-"}`)}</td>
          <td>
            {oneLine(
              `${user.status || "-"}`,
              user.status === "active" ? "success" : user.status === "inactive" ? "danger" : "info"
            )}
          </td>
          <td>{oneLine(`${new Date(user.created_at).toLocaleString()}`)}</td>
          <td>{oneLine(`${new Date(user.updated_at).toLocaleString()}`)}</td>
          <td>
            <div className="btn-list flex-nowrap">
              <ActionButton
                icon={<IconUser className="icon" />}
                tooltip="Профиль"
                action={() => navigate(`/user/${user.id}`)}
                color={"info"}
                modal={false}
              />
              {(isSuperAdmin || (!isAdminPath && !isSuperAdmin)) && (
                <ActionButton
                  icon={<IconUserEdit className="icon" />}
                  tooltip="Изменить"
                  action={() => editUserData(user)}
                  color={"info"}
                  modal={true}
                  modal_id="#modal-new-user"
                />
              )}
              {(isSuperAdmin || (!isAdminPath && !isSuperAdmin)) && (
                <ActionButton
                  icon={user.status === "inactive" ? <IconLockOpen className="icon" /> : <IconLock className="icon" />}
                  tooltip={`${user.status === "inactive" ? "Разблокировать" : "Заблокировать"}`}
                  action={() => onDangerModal(user, user.status === "inactive" ? "unblock" : "block")}
                  color={`${user.status === "inactive" ? "success" : "danger"}`}
                  modal={true}
                  modal_id="#modal-danger"
                />
              )}
              {isSuperAdmin && (
                <ActionButton
                  icon={<IconUserX className="icon" />}
                  tooltip="Удалить"
                  action={() => onDangerModal(user, "delete")}
                  color={"danger"}
                  modal={true}
                  modal_id="#modal-danger"
                />
              )}
            </div>
          </td>
        </tr>
      ))}
    </>
  );

  const usersTable = Table(usersTableHeaders, usersList, true, users, updateFirstEntry, getAndSetUsers);

  const usersHeader = isAdminPath ? "Администраторы" : "Пользователи";
  const usersSubheaderText = `Количество ${isAdminPath ? "администраторов" : "пользователей"}: `;
  const usersSubheaderCount = users.total;
  const headerButtonText =
    isAdminPath && isSuperAdmin
      ? "Добавить администратора"
      : isAdminPath && !isSuperAdmin
      ? undefined
      : "Новый пользователь";
  const headerButtonModal = `#modal-new-${isAdminPath && isSuperAdmin ? "admin" : "user"}`;

  return (
    <>
      <PageLayout
        children={usersTable}
        isLoading={isLoading}
        filters={isAdminPath ? false : true}
        filtersList={userFiltersList}
        appendFilter={appendParam}
        clearFilters={clearFilters}
        search={true}
        searchAPI={getAndSetUsers}
        header={usersHeader}
        subheaderText={usersSubheaderText}
        subheaderCount={usersSubheaderCount}
        dataLength={users.data.length}
        buttonText={headerButtonText}
        buttonModal={headerButtonModal}
        dateRange={false}
        getExcel={isAdminPath ? false : true}
        excelParams={searchParams}
        excelCount={users.total}
      />
      <NewUser userData={editingUser} updateUsers={getAndSetUsers} cancelEdit={editUserData} />
      <NewAdmin updateUsers={getAndSetUsers} />
      <DangerModal dangerDescription={dangerDescription} confirm={confirmDanger} />
    </>
  );
}
