import { Api } from "api";

export const getAPCalls = async (search: URLSearchParams, page?: number) => {
  let url = "/users/v1/admin/apCall";
  let params = search ? `?${search}` : "";
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`${url}${params}`, {
    params: { page },
    headers,
  });
  return res.data;
};
