import { useState } from "react";
import { useSharedState } from "sharedStateProvider";
import { IconDownload } from "@tabler/icons-react";
import { getExcel } from "api/users";

export default function GetExcelBtn({
  params,
  count,
}: {
  params: URLSearchParams;
  count: number;
}) {
  const { notify } = useSharedState();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onExcelDownload = async () => {
    try {
      setIsLoading(true);
      if (count > 100) {
        notify(
          "Нельзя выгружать более 100 пользователей",
          "warning",
          "Ошибка при выгрузке пользователей"
        );
      } else {
        const response = await getExcel(params);

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "users.xlsx");
        document.body.appendChild(link);
        link.click();
      }
    } catch (error: any) {
      let err = error.response.data.error;
      notify(err, "danger", "Ошибка при выгрузке пользователей");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      className="btn btn-text text-success"
      onClick={onExcelDownload}
      disabled={isLoading}
    >
      <IconDownload className="icon" />
      Скачать Excel
    </button>
  );
}
