import { UserType } from "types/user-types";
import { FiltersList, RangeType } from "types/page-layout-types";

export type ChatType = {
  id: string;
  create_at: number;
  creator_id: string;
  delete_at: number;
  description: string;
  display_name: string;
  total_msg_count: number;
  msg_count: number;
  member_count: number;
  mention_count: number;
  last_post_at: number;
  last_post_id: string;
  logo_file_id: string;
  type: "direct" | "private_group";
  update_at: number;
};

export type ChatsResponseType = {
  data: ChatType[];
  page: number;
  size: number;
  sort: string;
  total: number;
};

export type ChatMemberType = {
  channel_id: string;
  user_id: string;
  create_at: number;
  roles: "admin" | "member";
  last_view_at: number;
  msg_count: number;
  mention_count: number;
  notify_props: {
    push: string;
  };
  last_update_at: number;
  user_info: UserType | undefined;
};

export type ChatPostType = {
  id: string;
  channel_id: string;
  create_at: number;
  delete_at: number;
  edit_at: number;
  file_ids: any;
  forwarded_from_id: string;
  has_reactions: boolean;
  is_pinned: boolean;
  message: string;
  props: any;
  reply_to_id: string;
  type: string;
  update_at: number;
  user_id: string;
};

export type ChatPostResponseType = {
  order: number[];
  posts: {
    [key: number]: ChatPostType;
  };
  next_post_id: string;
  prev_post_id: string;
  has_next: boolean;
};

export type ChatFileType = {
  id: string;
  unique_file_id: string;
  filename: string;
  real_filename: string;
  extension: string;
  channel_id: string;
  post_id: string;
  user_id: string;
  type: string;
  mime_type: string;
  url: string;
  size: number;
  public: boolean;
};

export const emptyChat: ChatType = {
  id: "",
  create_at: 0,
  creator_id: "",
  delete_at: 0,
  description: "",
  display_name: "",
  total_msg_count: 0,
  msg_count: 0,
  member_count: 0,
  mention_count: 0,
  last_post_at: 0,
  last_post_id: "",
  logo_file_id: "",
  type: "direct",
  update_at: 0,
};

export const ChatsFiltersList: FiltersList = {
  type: [
    {
      name: "Тип чата",
      value: "",
      filter_name: "chat_type",
      disabled: true,
    },
    {
      name: "direct",
      value: "direct",
      filter_name: "chat_type",
      disabled: false,
    },
    {
      name: "private_group",
      value: "private_group",
      filter_name: "chat_type",
      disabled: false,
    },
  ],
};

export const ChatsRangeNames: RangeType = {
  from: "from",
  to: "to",
};
