import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { MeType, emptyMe } from "types/user-types";
import { BridgeMethodResponseType } from "types/mini-app-types";
import { emptyPagination } from "types/pagination-types";
import { Store, NOTIFICATION_TYPE } from "react-notifications-component";
import PageLoader from "components/page-loader";
import SignIn from "components/auth/sign-in";
import { authorize } from "api/auth";
import { getMeAdmin } from "api/users";
import { getBridgeMethods } from "api/bridge";

interface SharedState {
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  accessToken: string | null;
  setAccessToken: React.Dispatch<React.SetStateAction<string | null>>;
  refreshToken: string | null;
  setRefreshToken: React.Dispatch<React.SetStateAction<string | null>>;
  login: (phone: string, password: string) => Promise<void>;
  logout: () => void;
  isSuperAdmin: boolean;
  bridgeMethods: BridgeMethodResponseType;
  getAndSetBridgeMethods: () => Promise<void>;
  notify: (
    message: string,
    type: NOTIFICATION_TYPE,
    title?: string,
    duration?: number
  ) => void;
}

const SharedStateContext = createContext<SharedState | undefined>(undefined);

export const SharedStateProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  const [pageLoader, setPageLoader] = useState<boolean>(true);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const localAccessToken = localStorage.getItem("accessToken");
  const localRefreshToken = localStorage.getItem("refreshToken");
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem("accessToken")
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    localStorage.getItem("refreshToken")
  );
  const [me, setMe] = useState<MeType>(emptyMe);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [bridgeMethods, setBridgeMethods] = useState<BridgeMethodResponseType>(emptyPagination);

  const login = async (phone: string, password: string) => {
    await authorize({ phone, password })
      .then(() => {
        setAccessToken(localStorage.getItem("accessToken"));
        setRefreshToken(localStorage.getItem("refreshToken"));
        notify("Авторизация прошла успешно", "success");
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при авторизации");
      });
  };

  const logout = () => {
    notify("Деавторизация прошла успешно", "success");
    navigate("/");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setAccessToken(null);
    setRefreshToken(null);
  };

  const getAndSetMe = async () => {
    await getMeAdmin()
      .then((res: MeType) => {
        setMe(res);
        setIsSuperAdmin(res.user_type === "superadmin" ? true : false);
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении профиля");
      });
  };

  const getAndSetBridgeMethods = async (page?: number) => {
    setBridgeMethods(emptyPagination);
    await getBridgeMethods(page)
      .then((res: BridgeMethodResponseType) => setBridgeMethods(res))
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении методов бриджа");
      });
  };

  const notify = (
    message: string,
    type: NOTIFICATION_TYPE,
    title?: string,
    duration?: number
  ) => {
    Store.addNotification({
      title,
      message,
      type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__bounceIn"],
      animationOut: ["animate__animated", "animate__bounceOut"],
      dismiss: { duration: duration || 3000 },
    });
  };

  const initApp = async () => {
    Promise.all([await getAndSetMe(), await getAndSetBridgeMethods()]);
    setPageLoader(false);
  };

  const value: SharedState = {
    darkMode,
    setDarkMode,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    login,
    logout,
    isSuperAdmin,
    bridgeMethods,
    getAndSetBridgeMethods,
    notify,
  };

  const hasToken: boolean = useMemo(() => {
    if (localAccessToken !== null || localRefreshToken !== null) {
      setAccessToken(localAccessToken);
      setRefreshToken(localRefreshToken);
      return true;
    } else return false;
  }, [localAccessToken, localRefreshToken]);

  const DynamicChildren = () => {
    switch (hasToken) {
      case true:
        if (!pageLoader) {
          return children;
        } else {
          return <PageLoader />;
        }

      default:
        return <SignIn />;
    }
  };

  useEffect(() => {
    accessToken !== null && initApp();
  }, [accessToken]);

  useEffect(() => {
    const tablerTheme = localStorage.getItem("tablerTheme");

    if (tablerTheme === "dark") {
      setDarkMode(true);
      document.body.setAttribute("data-bs-theme", tablerTheme);
    } else {
      setDarkMode(false);
      document.body.removeAttribute("data-bs-theme");
    }
  }, [darkMode]);

  return (
    <SharedStateContext.Provider value={value}>
      {DynamicChildren()}
    </SharedStateContext.Provider>
  );
};

export const useSharedState = (): SharedState => {
  const context = useContext(SharedStateContext);
  if (!context) {
    throw new Error("useSharedState must be used within a SharedStateProvider");
  }
  return context;
};
