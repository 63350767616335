import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import { SharedStateProvider } from "sharedStateProvider";
import "assets/js/demo.js";
import "assets/js/tabler.js";
import "assets/css/demo.css";
import "assets/css/tabler.css";
import "assets/css/tabler-social.css";
import "assets/css/tabler-vendors.css";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import UsersList from "components/users/users-list";
import UserProfile from "components/users/user-profile";
import APCalls from "components/ap-calls";
import Versions from "components/versions";
import MiniAppsList from "components/mini-apps/mini-apps-list";
import Moderation from "components/mini-apps/moderation";
import BridgeMethods from "components/mini-apps/bridge-methods";
import EnabledNotifications from "components/notifications/enabled-notifications";
import Notifications from "components/notifications";
// import FilesList from "components/files";
import ChatsList from "components/chats/chats-list";
import ChatPage from "components/chats/chat-page";
import reportWebVitals from "reportWebVitals";

const App = () => {
  return (
    <Router>
      <SharedStateProvider>
        <Routes>
          <Route path="/" element={<UsersList />} />
          <Route path="/admins" element={<UsersList />} />
          <Route path="/user/:id" element={<UserProfile />} />
          <Route path="/ap-calls" element={<APCalls />} />
          <Route path="/versions" element={<Versions />} />
          <Route path="/mini-apps" element={<MiniAppsList />} />
          <Route path="/moderation" element={<Moderation />} />
          <Route path="/bridge-methods" element={<BridgeMethods />} />
          <Route path="/enabled-notifications" element={<EnabledNotifications />} />
          <Route path="/notifications" element={<Notifications />} />
          {/* <Route path="/files" element={<FilesList />} /> */}
          <Route path="/chats" element={<ChatsList />} />
          <Route path="/chats/:id" element={<ChatPage />} />
        </Routes>
      </SharedStateProvider>
    </Router>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ReactNotifications />
    <App />
  </React.StrictMode>
);

reportWebVitals();
