import { useEffect, useState } from "react";
import {
  VersionType,
  VersionFormType,
  BlankVersionForm,
  BlankVersion,
} from "types/version-type";
import { addVersion, editVersion } from "api/versions";
import { useSharedState } from "sharedStateProvider";

export default function NewVersion({
  verData,
  updateVerList,
  cancelEdit,
}: {
  verData: VersionType;
  updateVerList: () => void;
  cancelEdit: (value: VersionType) => void;
}) {
  const { notify } = useSharedState();

  const [versionForm, setVersionForm] =
    useState<VersionFormType>(BlankVersionForm);

  const handleChange = (key: string, value: string | number | boolean) => {
    setVersionForm((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const cancelVersionCreation = () => {
    let allInputs = document.querySelectorAll("input");
    allInputs.forEach((singleInput) => {
      singleInput.value = "";
    });
    setVersionForm(BlankVersionForm);
    cancelEdit(BlankVersion);
  };

  const submitVersion = async () => {
    const versionPayload: VersionType = {
      major: versionForm.major,
      minor: versionForm.minor,
      patch: versionForm.patch,
      android_available: versionForm.android_available,
      huawei_available: versionForm.huawei_available,
      ios_available: versionForm.ios_available,
      required: versionForm.required,
      notes: {
        ru: versionForm.notesRu,
        kz: versionForm.notesKz,
        eng: versionForm.notesEn,
      },
    };

    if (verData.id) {
      editVersion(verData.id.toString(), versionPayload)
        .then(() => {
          notify("Версия успешно изменена", "success");
          updateVerList();
        })
        .catch((error: any) => {
          let err = error.response.data.error;
          notify(err, "danger", "Ошибка при редактировании версии");
        })
        .finally(() => cancelVersionCreation());
    } else {
      addVersion(versionPayload)
        .then(() => {
          notify("Версия успешно добавлена", "success");
          updateVerList();
        })
        .catch((error: any) => {
          let err = error.response.data.error;
          notify(err, "danger", "Ошибка при добавлении версии");
        })
        .finally(() => cancelVersionCreation());
    }
  };

  useEffect(() => {
    setVersionForm({
      major: verData.major,
      minor: verData.minor,
      patch: verData.patch,
      android_available: verData.android_available,
      huawei_available: verData.huawei_available,
      ios_available: verData.ios_available,
      required: verData.required,
      notesRu: verData.notes.ru,
      notesKz: verData.notes.kz,
      notesEn: verData.notes.eng,
    });
  }, [verData]);

  return (
    <div
      className="modal modal-blur fade"
      id="modal-add-version"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {verData.id ? "Редактирование версии" : "Новая версия"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={cancelVersionCreation}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-4">
                <div className="mb-3">
                  <label className="form-label required">Major</label>
                  <input
                    type="number"
                    className="form-control"
                    name="example-text-input"
                    placeholder="0"
                    value={versionForm.major}
                    onChange={(event) => {
                      handleChange("major", +event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <label className="form-label required">Minor</label>
                  <input
                    type="number"
                    className="form-control"
                    name="example-text-input"
                    placeholder="0"
                    value={versionForm.minor}
                    onChange={(event) => {
                      handleChange("minor", +event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <label className="form-label required">Patch</label>
                  <input
                    type="number"
                    className="form-control"
                    name="example-text-input"
                    placeholder="0"
                    value={versionForm.patch}
                    onChange={(event) => {
                      handleChange("patch", +event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-label">Доступность</div>
              <div>
                <label className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={versionForm.ios_available}
                    onChange={(event) => {
                      handleChange("ios_available", event.target.checked);
                    }}
                  />
                  <span className="form-check-label">iOS</span>
                </label>
                <label className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={versionForm.android_available}
                    onChange={(event) => {
                      handleChange("android_available", event.target.checked);
                    }}
                  />
                  <span className="form-check-label">Android</span>
                </label>
                <label className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={versionForm.huawei_available}
                    onChange={(event) => {
                      handleChange("huawei_available", event.target.checked);
                    }}
                  />
                  <span className="form-check-label">Huawei</span>
                </label>
                <label className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={versionForm.required}
                    onChange={(event) => {
                      handleChange("required", event.target.checked);
                    }}
                  />
                  <span className="form-check-label">Необходимость</span>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="mb-3">
                  <label className="form-label required">Примечание (RU)</label>
                  <textarea
                    rows={5}
                    className="form-control form-textarea-no-resize"
                    placeholder="Примечание к версии на русском языке"
                    value={versionForm.notesRu}
                    onChange={(event) => {
                      handleChange("notesRu", event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <label className="form-label required">Примечание (KZ)</label>
                  <textarea
                    rows={5}
                    className="form-control form-textarea-no-resize"
                    placeholder="Примечание к версии на казахском языке"
                    value={versionForm.notesKz}
                    onChange={(event) => {
                      handleChange("notesKz", event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <label className="form-label required">Примечание (EN)</label>
                  <textarea
                    rows={5}
                    className="form-control form-textarea-no-resize"
                    placeholder="Примечание к версии на английском языке"
                    value={versionForm.notesEn}
                    onChange={(event) => {
                      handleChange("notesEn", event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-link link-secondary"
              type="reset"
              data-bs-dismiss="modal"
              onClick={cancelVersionCreation}
            >
              Отмена
            </button>
            <button
              className="btn btn-primary ms-auto"
              type="submit"
              data-bs-dismiss="modal"
              onClick={submitVersion}
              disabled={
                versionForm.major === undefined ||
                versionForm.minor === undefined ||
                versionForm.patch === undefined ||
                !versionForm.notesRu ||
                !versionForm.notesKz ||
                !versionForm.notesEn
              }
            >
              {verData.id ? "Сохранить" : "Создать версию"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
