import { Api } from "api";

export const getMiniApps = async (search: URLSearchParams, page?: number) => {
  let url = "/mini_apps/v1/admin";
  let params = search ? `?${search}` : "";
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`${url}${params}`, {
    params: { page },
    headers,
  });
  return res.data;
};

export const getMiniApp = async (id: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/mini_apps/v1/admin/${id}/owner`, { headers });
  return res.data;
};

export const createMiniApp = async (payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.post(`/mini_apps/v1/admin/`, payload, { headers });
  return res.data;
};

export const putMiniApp = async (id: string, payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.put(`/mini_apps/v1/admin/${id}`, payload, { headers });
  return res.data;
};

export const deleteMiniApp = async (id: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.delete(`/mini_apps/v1/admin/${id}`, { headers });
  return res.data;
};

export const getUpdates = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/mini_apps/v1/admin/updates`, { headers });
  return res.data;
};

export const reviewRequest = async (id: string, payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.put(`/mini_apps/v1/admin/${id}/request`, payload, { headers });
  return res.data;
};

export const reviewUpdate = async (id: string, payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.put(`/mini_apps/v1/admin/update/${id}/review`, payload, { headers });
  return res.data;
};

export const closeUpdate = async (id: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.put(`/mini_apps/v1/admin/update/${id}/close`, {}, { headers });
  return res.data;
};

export const prepareLogoUpload: any = async (payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.post("/files/v1/upload", payload, { headers });
  return res.data;
};

export const uploadLogo: any = async (query = "", payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.put("/files/v1/upload" + query, payload, { headers });
  return res.data;
};
