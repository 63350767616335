import { FiltersList, RangeType } from "types/page-layout-types";

export type APCallType = {
  id: number;
  ap_sub: string;
  scope: string;
  target: string;
  request_url: string;
  request_body: string;
  request_header: string;
  response_code: number;
  response_body: string;
  response_time: number;
  created_at: string;
};

export type APCallsResponseType = {
  data: APCallType[];
  page: number;
  size: number;
  sort: string;
  total: number;
};

export const APCallsFiltersList: FiltersList = {
  type: [
    {
      name: "Сорт. по коду",
      value: "",
      filter_name: "response_code",
      disabled: true,
    },
    {
      name: "200 => 400",
      value: "asc",
      filter_name: "response_code",
      disabled: false,
    },
    {
      name: "400 => 200",
      value: "desc",
      filter_name: "response_code",
      disabled: false,
    },
  ],
};

export const APCallsRangeNames: RangeType = {
  from: "from",
  to: "to",
};
