import { PaginationType, emptyPagination } from "types/pagination-types";
import Pagination from "components/pagination";

export interface tableHeader {
  header_text: string;
  narrow: boolean;
  wrap: boolean;
}

export default function Table(
  headers: tableHeader[],
  tableBody: React.JSX.Element,
  pagination: boolean,
  paginationInfo?: PaginationType,
  updateFirstEntry?: (value: number) => void,
  updateInfo?: () => Promise<any>
) {
  return (
    <div className="col-12">
      <div className="card">
        <div className="table-responsive">
          <table className="table table-vcenter table-mobile-md card-table">
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th
                    className={`${header.narrow && "w-1"} ${
                      header.wrap && "text-wrap"
                    }`}
                    key={index}
                  >
                    {header.header_text}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{tableBody}</tbody>
          </table>
        </div>
        {pagination && (
          <Pagination
            paginationInfo={paginationInfo || emptyPagination}
            updateFirstEntry={updateFirstEntry || (() => false)}
            updateInfo={updateInfo || (async () => false)}
          />
        )}
      </div>
    </div>
  );
}
