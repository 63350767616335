import { useState, useEffect } from "react";
import { useSharedState } from "sharedStateProvider";
import { NotificationsResponseType } from "types/notification-type";
import { emptyPagination } from "types/pagination-types";
import PageLayout from "components/page-layout";
import Table, { tableHeader } from "components/tables/table";
import { oneLine, linkLine } from "components/tables/table-parts";
import { getMiniAppNotifications } from "api/notifications";

export default function Notifications() {
  const { notify } = useSharedState();

  const [notifications, setNotifications] =
    useState<NotificationsResponseType>(emptyPagination);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstEntry, setFirstEntry] = useState<number>(0);

  const updateFirstEntry = (value: number) => {
    setFirstEntry(value);
  };

  const getAndSetNotifications = async () => {
    setIsLoading(true);
    getMiniAppNotifications()
      .then((res: NotificationsResponseType) => setNotifications(res))
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при загрузке уведомлений");
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    Promise.all([getAndSetNotifications()]);
  }, []);

  const notificationsTableHeaders: tableHeader[] = [
    { header_text: "№", narrow: true, wrap: false },
    { header_text: "Заголовок", narrow: false, wrap: false },
    { header_text: "Сообщение", narrow: false, wrap: false },
    { header_text: "Ссылка", narrow: false, wrap: false },
    { header_text: "Создано", narrow: false, wrap: false },
  ];

  const notificationsList = (
    <>
      {notifications.data.map((not, index) => (
        <tr key={index}>
          <td>{oneLine((firstEntry + index).toString())}</td>
          <td>{oneLine(not.title)}</td>
          <td>{oneLine(not.message)}</td>
          <td>{linkLine(not.link)}</td>
          <td>{oneLine(new Date(not.created_at).toLocaleString())}</td>
        </tr>
      ))}
    </>
  );

  const notificationsTable = Table(
    notificationsTableHeaders,
    notificationsList,
    true,
    notifications,
    updateFirstEntry,
    getAndSetNotifications
  );

  const notificationsHeader = "Уведомления";
  const notificationsSubheaderText = "Количество уведомлений: ";
  const notificationsSubheaderCount = notifications.total;

  return (
    <>
      <PageLayout
        children={notificationsTable}
        isLoading={isLoading}
        filters={false}
        search={false}
        header={notificationsHeader}
        subheaderText={notificationsSubheaderText}
        subheaderCount={notificationsSubheaderCount}
        dataLength={notifications.data.length}
        dateRange={false}
      />
    </>
  );
}
