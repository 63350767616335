import { useEffect, useState } from "react";
import { BridgeMethodType, emptyBridgeMethod } from "types/mini-app-types";
import { useSharedState } from "sharedStateProvider";
import { createBridgeMethod, editBridgeMethod } from "api/bridge";

export default function NewBridgeMethod({
  bridgeData,
  clearBridgeData,
}: {
  bridgeData: any;
  clearBridgeData: () => void;
}) {
  const { getAndSetBridgeMethods, notify } = useSharedState();

  const [bridgeForm, setBridgeForm] =
    useState<BridgeMethodType>(emptyBridgeMethod);

  const handleChange = (key: string, value: string) => {
    console.log("bridgeForm", bridgeForm);

    setBridgeForm((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const cancelBridgeCreation = () => {
    clearBridgeData();
    let allInputs = document.querySelectorAll("input");
    allInputs.forEach((singleInput) => {
      singleInput.value = "";
    });
    setBridgeForm(emptyBridgeMethod);
  };

  const submitBridge = async () => {
    if (bridgeData.id) {
      editBridgeMethod(bridgeForm.name, bridgeForm)
        .then(() => {
          notify("Метод успешно изменён", "success");
          getAndSetBridgeMethods();
        })
        .catch((error: any) => {
          let err = error.response.data.error;
          notify(err, "danger", "Ошибка при редактировании метода");
        })
        .finally(() => cancelBridgeCreation());
    } else {
      createBridgeMethod(bridgeForm)
        .then(() => {
          notify("Метод успешно создан", "success");
          getAndSetBridgeMethods();
        })
        .catch((error: any) => {
          let err = error.response.data.error;
          notify(err, "danger", "Ошибка при создании метода");
        })
        .finally(() => cancelBridgeCreation());
    }
  };

  useEffect(() => {
    setBridgeForm({
      id: bridgeData?.id,
      name: bridgeData?.name,
      description: bridgeData?.description,
    });
  }, [bridgeData]);

  return (
    <div
      className="modal modal-blur fade"
      id="modal-new-bridge"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-m modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {bridgeData.id ? "Редактирование метода" : "Новый метод"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={cancelBridgeCreation}
            ></button>
          </div>
          <div className="modal-body">
            <div className="col">
              <div className="mb-3">
                <label className="form-label required">Название</label>
                <input
                  type="text"
                  className="form-control"
                  name="example-text-input"
                  placeholder="Название"
                  value={bridgeForm.name}
                  onChange={(event) => {
                    handleChange("name", event.target.value);
                  }}
                  disabled={bridgeData.id}
                />
              </div>
            </div>
            <div className="col">
              <div className="mb-3">
                <label className="form-label required">Описание</label>
                <input
                  type="text"
                  className="form-control"
                  name="example-text-input"
                  placeholder="Описание"
                  value={bridgeForm.description}
                  onChange={(event) => {
                    handleChange("description", event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-link link-secondary"
              type="reset"
              data-bs-dismiss="modal"
              onClick={cancelBridgeCreation}
            >
              Отмена
            </button>
            <button
              className="btn btn-primary ms-auto"
              type="submit"
              data-bs-dismiss="modal"
              onClick={submitBridge}
              disabled={!bridgeForm.name || !bridgeForm.description}
            >
              {bridgeData.id ? "Сохранить" : "Создать метод"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
