import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSharedState } from "sharedStateProvider";
import { ChatsResponseType, ChatsRangeNames, ChatsFiltersList, ChatType, emptyChat } from "types/chat-type";
import { emptyPagination } from "types/pagination-types";
import PageLayout from "components/page-layout";
import Table, { tableHeader } from "components/tables/table";
import { oneLine } from "components/tables/table-parts";
import { getChats, deleteChat } from "api/chats";
import DangerModal from "components/modals/danger";
import ActionButton from "components/tables/action-btn";
import { IconInfoCircle, IconTrash } from "@tabler/icons-react";

export default function ChatsList() {
  const navigate = useNavigate();

  const { notify } = useSharedState();

  const [chats, setChats] = useState<ChatsResponseType>(emptyPagination);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstEntry, setFirstEntry] = useState<number>(0);

  const [deletingChat, setDeletingChat] = useState<ChatType>(emptyChat);
  const dangerDescription = `Вы действительно хотите удалить чат "${deletingChat.display_name}"?`;

  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());

  const updateFirstEntry = (value: number) => {
    setFirstEntry(value);
  };

  const getAndSetChats = async (page?: number) => {
    setIsLoading(true);
    setChats(emptyPagination);
    getChats(searchParams, page)
      .then((res: ChatsResponseType) => {
        setChats(res);
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении чатов");
      })
      .finally(() => setIsLoading(false));
  };

  const deleteChatById = async () => {
    setIsLoading(true);
    deleteChat(deletingChat.id)
      .then(() => {
        notify("Чат успешно удалён", "success");
        getAndSetChats();
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при удалении чата");
      })
      .finally(() => setIsLoading(false));
  };

  const appendParam = (key: string, value: string) => {
    if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  };

  const clearFilters = async (param?: string) => {
    if (param) {
      param && searchParams.delete(param);
    } else {
      searchParams.delete("chat_type");
      searchParams.delete("search");
      searchParams.delete("from");
      searchParams.delete("to");
    }
  };

  useEffect(() => {
    Promise.all([getAndSetChats()]);
  }, []);

  const chatsTableHeaders: tableHeader[] = [
    { header_text: "№", narrow: true, wrap: false },
    { header_text: "Название", narrow: false, wrap: false },
    { header_text: "Кол-во участников", narrow: false, wrap: false },
    { header_text: "Тип чата", narrow: false, wrap: false },
    { header_text: "Создан", narrow: false, wrap: false },
    { header_text: "", narrow: true, wrap: false },
  ];

  const chatsList = (
    <>
      {chats.data.map((chat, index) => (
        <tr key={index}>
          <td>{oneLine((firstEntry + index).toString())}</td>
          <td>{oneLine(chat.display_name)}</td>
          <td>{oneLine(chat.member_count.toString())}</td>
          <td>{oneLine(chat.type === "direct" ? "Личный" : "Группа", chat.type === "direct" ? "info" : "success")}</td>
          <td>{oneLine(new Date(chat.create_at * 1000).toLocaleString())}</td>
          <td className="text-end">
            <div className="btn-list flex-nowrap">
              <ActionButton
                icon={<IconInfoCircle className="icon" />}
                tooltip="Открыть чат"
                action={() => navigate(`/chats/${chat.id}`)}
                color={"info"}
                modal={false}
              />
              <ActionButton
                icon={<IconTrash className="icon" />}
                tooltip="Удалить чат"
                action={() => setDeletingChat(chat)}
                color={"danger"}
                modal={true}
                modal_id="#modal-danger"
              />
            </div>
          </td>
        </tr>
      ))}
    </>
  );

  const chatsTable = Table(chatsTableHeaders, chatsList, true, chats, updateFirstEntry, getAndSetChats);

  const chatsHeader = "Чаты";
  const chatsSubheaderText = "Количество чатов: ";
  const chatsSubheaderCount = chats.total;

  return (
    <>
      <PageLayout
        children={chatsTable}
        isLoading={isLoading}
        filters={true}
        filtersList={ChatsFiltersList}
        appendFilter={appendParam}
        clearFilters={clearFilters}
        search={true}
        searchAPI={getAndSetChats}
        header={chatsHeader}
        subheaderText={chatsSubheaderText}
        subheaderCount={chatsSubheaderCount}
        dataLength={chats.data.length}
        dateRange={true}
        rangeNames={ChatsRangeNames}
      />
      <DangerModal dangerDescription={dangerDescription} confirm={deleteChatById} />
    </>
  );
}
