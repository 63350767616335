import { useEffect, useState } from "react";
import { useSharedState } from "sharedStateProvider";
import { MiniAppType, MiniAppsResponseType } from "types/mini-app-types";
import { emptyPagination } from "types/pagination-types";
import PageLayout from "components/page-layout";
import Table, { tableHeader } from "components/tables/table";
import { avatarWithTwoLines, oneLine, mutedLine, checkOrXIcon } from "components/tables/table-parts";
import NewMiniApp from "components/modals/new-mini-app";
import DangerModal from "components/modals/danger";
import { deleteMiniApp, getMiniApps } from "api/mini-apps";
import ActionButton from "components/tables/action-btn";
import { IconInfoCircle, IconTrash } from "@tabler/icons-react";

export default function MiniAppsList() {
  const { notify } = useSharedState();

  const [miniApps, setMiniApps] = useState<MiniAppsResponseType>(emptyPagination);
  const [firstEntry, setFirstEntry] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());

  const updateFirstEntry = (value: number) => {
    setFirstEntry(value);
  };

  const [isReadingApp, setIsReadingApp] = useState<boolean>(false);
  const [appId, setAppId] = useState<string>("");

  const [deletingAppId, setDeletingAppId] = useState<string>("");

  const [dangerDescription, setDangerDescription] = useState<string>("");
  const dangerText = "Вы действительно хотите удалить приложение";

  const toggleAppReading = (value: boolean) => {
    setIsReadingApp(value);
  };

  const editAppData = (appData: MiniAppType) => {
    toggleAppReading(true);
    setAppId(appData.id);
  };

  const clearAppId = () => {
    setIsReadingApp(false);
    setAppId("");
  };

  const onDangerModal = (miniApp: MiniAppType) => {
    setDeletingAppId(miniApp.id);
    setDangerDescription(dangerText + ` "${miniApp.names.ru}"?`);
  };

  const deleteMethod = async () => {
    deleteMiniApp(deletingAppId)
      .then(() => {
        notify("Мини-приложение успешно удалено", "success");
        getAndSetMiniApps();
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при удалении мини-приложения");
      });
  };

  const getAndSetMiniApps = async (page?: number) => {
    setIsLoading(true);
    setMiniApps(emptyPagination);
    getMiniApps(searchParams, page)
      .then((res: MiniAppsResponseType) => {
        setMiniApps(res);
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении мини-приложений");
      })
      .finally(() => setIsLoading(false));
  };

  const appendParam = (key: string, value: string) => {
    if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  };

  const clearFilters = async (param?: string) => {
    if (param) {
      param && searchParams.delete(param);
    } else {
      searchParams.delete("type");
      searchParams.delete("status");
      searchParams.delete("search");
    }
  };

  useEffect(() => {
    getAndSetMiniApps();
  }, []);

  const miniAppsTableHeaders: tableHeader[] = [
    { header_text: "№", narrow: false, wrap: false },
    { header_text: "Мини-приложение", narrow: false, wrap: false },
    { header_text: "Описание", narrow: false, wrap: false },
    { header_text: "Статус", narrow: false, wrap: false },
    { header_text: "Global", narrow: true, wrap: false },
    { header_text: "", narrow: true, wrap: false },
  ];

  const miniAppsList = (
    <>
      {miniApps.data.map((miniApp, index) => (
        <tr key={index}>
          <td>{oneLine((firstEntry + index).toString())}</td>
          <td>{avatarWithTwoLines(miniApp.logo_url, "app", miniApp.names.ru, miniApp.link)}</td>
          <td>{oneLine(miniApp.descriptions.ru)}</td>
          <td>{mutedLine(miniApp.mode)}</td>
          <td>{checkOrXIcon(miniApp.global)}</td>
          <td>
            <div className="btn-list flex-nowrap">
              <ActionButton
                icon={<IconInfoCircle className="icon" />}
                tooltip="Подробнее"
                action={() => editAppData(miniApp)}
                color={"info"}
                modal={true}
                modal_id="#modal-new-mini-app"
              />
              <ActionButton
                icon={<IconTrash className="icon" />}
                tooltip="Удалить"
                action={() => onDangerModal(miniApp)}
                color={"danger"}
                modal={true}
                modal_id="#modal-danger"
              />
            </div>
          </td>
        </tr>
      ))}
    </>
  );

  const miniAppsTable = Table(miniAppsTableHeaders, miniAppsList, true, miniApps, updateFirstEntry, getAndSetMiniApps);

  const miniAppsHeader = "Мини-приложения";
  const miniAppsSubheaderText = "Количество мини-приложений: ";
  const miniAppsSubheaderCount = miniApps.total;
  const headerButtonText = "Создать мини-приложение";
  const headerButtonModal = "#modal-new-mini-app";

  return (
    <>
      <PageLayout
        children={miniAppsTable}
        isLoading={isLoading}
        filters={false}
        appendFilter={appendParam}
        clearFilters={clearFilters}
        search={true}
        searchAPI={getAndSetMiniApps}
        header={miniAppsHeader}
        subheaderText={miniAppsSubheaderText}
        subheaderCount={miniAppsSubheaderCount}
        dataLength={miniApps.data.length}
        buttonText={headerButtonText}
        buttonModal={headerButtonModal}
        dateRange={false}
      />
      <NewMiniApp
        isReadingApp={isReadingApp}
        appId={appId}
        updateMiniApps={getAndSetMiniApps}
        clearAppId={clearAppId}
        toggleAppEdit={toggleAppReading}
      />
      <DangerModal dangerDescription={dangerDescription} confirm={deleteMethod} />
    </>
  );
}
