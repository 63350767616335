import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { PaginationType } from "types/pagination-types";

export default function Pagination({
  paginationInfo,
  updateFirstEntry,
  updateInfo,
}: {
  paginationInfo: PaginationType;
  updateFirstEntry: (value: number) => void;
  updateInfo: (page?: number) => Promise<any>;
}) {
  const [firstEntry, setFirstEntry] = useState<number>(0);
  const [lastEntry, setLastEntry] = useState<number>(0);
  const [pagesCount, setPagesCount] = useState<number[]>([]);
  const visiblePages = pagesCount.filter(
    (p) =>
      p === 1 ||
      p === pagesCount.length ||
      (p >= paginationInfo.page - 1 && p <= paginationInfo.page + 1)
  );

  const updatePagination = async (page?: number) => {
    page && updateInfo(page);

    let newFirstEntry = paginationInfo.size * (paginationInfo.page - 1) + 1;
    setFirstEntry(newFirstEntry);
    updateFirstEntry(newFirstEntry);
    setLastEntry(
      paginationInfo.total - paginationInfo.size * (paginationInfo.page - 1) >=
        paginationInfo.size
        ? paginationInfo.size * paginationInfo.page
        : paginationInfo.total
    );
    setPagesCount(
      Array.from(
        { length: Math.ceil(paginationInfo.total / paginationInfo.size) },
        (_, i) => i + 1
      )
    );
  };

  const prevPage = () => {
    paginationInfo.page > 1 && updatePagination(paginationInfo.page - 1);
  };

  const nextPage = () => {
    paginationInfo.page < pagesCount.length &&
      updatePagination(paginationInfo.page + 1);
  };

  useEffect(() => {
    updatePagination();
  }, [paginationInfo]);

  return (
    <>
      <div className="card-footer d-flex align-items-center">
        <p className="m-0 text-muted">
          Показаны {firstEntry}-{lastEntry} из {paginationInfo.total} записей
        </p>
        <ul className="pagination m-0 ms-auto">
          <li
            className={`page-item ${
              paginationInfo.page === 1 ? "disabled" : "cursor-pointer"
            }`}
            onClick={prevPage}
          >
            <div className="page-link">
              <IconChevronLeft className="icon" />
            </div>
          </li>
          {visiblePages.map((page, index) => {
            // Add ellipsis for cut off pages
            if (index > 0 && visiblePages[index - 1] !== page - 1) {
              return (
                <li className="d-flex page-item" key={index}>
                  <span className="page-link pe-none">...</span>
                  <div
                    onClick={() => updatePagination(page)}
                    className="page-link cursor-pointer"
                  >
                    {page}
                  </div>
                </li>
              );
            }

            return (
              <li
                className={`page-item ${
                  page === paginationInfo.page ? "active" : "cursor-pointer"
                }`}
                key={index}
                onClick={() =>
                  page !== paginationInfo.page && updatePagination(page)
                }
              >
                <div className="page-link">{page}</div>
              </li>
            );
          })}
          <li
            className={`page-item ${
              paginationInfo.page === pagesCount.length
                ? "disabled"
                : "cursor-pointer"
            }`}
            onClick={nextPage}
          >
            <div className="page-link">
              <IconChevronRight className="icon" />
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}
