import { Api } from "api";

export const getMeAdmin = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get("/auth/v1/meAdmin", { headers });
  return res.data;
};

export const getUsers = async (search: URLSearchParams, page?: number) => {
  let url = "/users/v1/admin/get";
  let params = search ? `?${search}` : "";
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`${url}${params}`, {
    params: { page },
    headers,
  });
  return res.data;
};

export const getUsersByIds = async (ids: string[]) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.post("/users/v1/ids", { ids: ids }, { headers });
  return res.data;
};

export const addUser = async (payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.post("/users/v1/admin", payload, { headers });
  return res.data;
};

export const editUser = async (id: string, payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.put(`/users/v1/admin/${id}`, payload, { headers });
  return res.data;
};

export const deleteUser = async (id: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.delete(`/users/v1/admin/${id}`, { headers });
  return res.data;
};

export const changeUserStatus = async (
  id: string,
  status: "active" | "inactive"
) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.put(
    `/users/v1/admin/status/${id}`,
    { status },
    {
      headers,
    }
  );
  return res.data;
};

export const getExcel = async (search: URLSearchParams) => {
  let url = "/users/v1/admin/excel";
  let params = search ? `?${search}` : "";
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`${url}${params}`, { headers, responseType: 'blob', });
  return res.data;
};

export const getAdmins = async (searchParams: URLSearchParams) => {
  let url = "/users/v1/admin/allAdmins";
  let params = searchParams ? `?${searchParams}` : "";
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`${url}${params}`, { headers });
  return res.data;
};

export const makeAdmin = async (payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.post("/users/v1/admin/appointAdmin", payload, {
    headers,
  });
  return res.data;
};

export const deleteAdmin = async (id: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.delete(`/users/v1/admin/${id}/deleteAdmin`, { headers });
  return res.data;
};

export const getUserSessions = async (id: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get("/auth/v1/sessions", {
    params: { id },
    headers,
  });
  return res.data;
};

export const deleteUserSessions = async (id: string, device_id: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.delete("/auth/v1/sessions/delete", {
    params: { id, device_id },
    headers,
  });
  return res.data;
};
