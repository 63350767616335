import React, { useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  FilterQueryType,
  FilterType,
  FiltersList,
  RangeType,
} from "types/page-layout-types";
import GetExcelBtn from "components/page-layout/get-excel";
import { IconX, IconPlus, IconTrash } from "@tabler/icons-react";
import NavbarContainer from "components/navbar";
import TableSkeleton from "components/tables/table-skeleton";
import CountSkeleton from "components/tables/count-skeleton";
import BlankSearch from "components/blank-pages/blank-search";
import formatDate from "components/helpers/format-date";

export default function PageLayout({
  children,
  isLoading,
  filters,
  filtersList,
  appendFilter,
  clearFilters,
  search,
  searchAPI,
  customHeader,
  header,
  subheaderText,
  subheaderCount,
  dataLength,
  buttonText,
  buttonModal,
  dateRange,
  rangeNames,
  getExcel,
  excelParams,
  excelCount,
}: {
  children: React.JSX.Element;
  isLoading: boolean;
  filters: boolean;
  filtersList?: FiltersList;
  appendFilter?: (name: string, value: string) => void;
  clearFilters?: (param?: string) => Promise<any>;
  search: boolean;
  searchAPI?: () => void;
  customHeader?: React.JSX.Element;
  header?: string | React.JSX.Element;
  subheaderText?: string;
  subheaderCount?: number;
  dataLength?: number;
  buttonText?: string;
  buttonModal?: string;
  dateRange: boolean;
  rangeNames?: RangeType;
  getExcel?: boolean;
  excelParams?: URLSearchParams;
  excelCount?: number;
}) {
  const location = useLocation();

  const [timeoutId, setTimeoutId] = useState<number | null>(null);
  const [filterQuery, setFilterQuery] = useState<FilterQueryType>({});
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const isAdminPath = useMemo(
    () => location.pathname === "/admins",
    [location.pathname]
  );

  const handleFilterChange = (value: string, filter: FilterType[]) => {
    setFilterQuery((prevFilterQuery) => ({
      ...prevFilterQuery,
      [filter[0].filter_name]: value,
    }));

    let name = filter.find((el) => el.value === value);
    appendFilter && appendFilter(name?.filter_name || "", value);
    startSearch();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchQuery && setSearchQuery(inputValue);
    appendFilter && appendFilter("search", inputValue);

    timeoutId && clearTimeout(timeoutId);

    const newTimeoutId = window.setTimeout(() => {
      startSearch();
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const handleDateChange = (param: string, value: string) => {
    switch (param) {
      case rangeNames?.from:
        setStartDate(value);
        break;
      case rangeNames?.to:
        setEndDate(value);
        break;
    }
    appendFilter && appendFilter(param, value);
  };

  const handleClearFilters = async () => {
    clearFilters && (await clearFilters());
    (searchQuery || isFiltersEmpty || startDate || endDate) && startSearch();
    setFilterQuery({});
    searchQuery && handleClearSearch();
    setStartDate("");
    setEndDate("");
  };

  const handleClearSearch = () => {
    clearFilters && clearFilters("search");
    setSearchQuery("");
    startSearch();
  };

  const startSearch = () => {
    searchAPI && searchAPI();
  };

  const isFiltersEmpty: boolean = useMemo(() => {
    for (const prop in filterQuery) {
      if (Object.hasOwn(filterQuery, prop)) {
        return true;
      }
    }

    return false;
  }, [filterQuery]);

  useEffect(() => {
    startDate && endDate && startSearch();
  }, [startDate, endDate]);

  useEffect(() => {
    handleClearFilters();
  }, [isAdminPath]);

  const headerFilters = (
    <div className="d-flex flex-grow-1 flex-lg-grow-0 gap-2">
      {filtersList &&
        Object.values(filtersList).map((filter, index) => (
          <select
            key={index}
            className="form-select"
            value={filterQuery[filter[0].filter_name] || ""}
            onChange={(e) => handleFilterChange(e.target.value, filter)}
          >
            {filter.map((option, index_2) => (
              <option
                key={index_2}
                value={option.value}
                disabled={option.disabled}
              >
                {option.name}
              </option>
            ))}
          </select>
        ))}
    </div>
  );

  const headerSearchInput = (
    <div className="flex-grow-1 flex-lg-grow-0">
      <div className="input-group input-group-flat">
        <input
          className="form-control d-inline-block"
          name="search-input"
          type="search"
          placeholder="Поиск"
          value={searchQuery}
          onChange={handleSearchChange}
          autoComplete="off"
        />
        <span className="input-group-text">
          {searchQuery && (
            <div
              className="link-secondary cursor-pointer"
              onClick={handleClearSearch}
            >
              <IconX className="icon" />
            </div>
          )}
        </span>
      </div>
    </div>
  );

  const headerDateRange = (
    <div className="d-flex flex-grow-1 flex-lg-grow-0 gap-2">
      <div className="col input-group input-group-flat">
        <span className="input-group-text pe-1">С</span>
        <input
          type="date"
          className="form-control ps-0"
          max={endDate || formatDate(new Date())}
          value={startDate}
          onChange={(e) =>
            handleDateChange(rangeNames?.from || "", e.target.value)
          }
        />
      </div>
      <div className="col input-group input-group-flat">
        <span className="input-group-text pe-1">По</span>
        <input
          type="date"
          className="form-control ps-0"
          min={startDate}
          max={formatDate(new Date())}
          value={endDate}
          onChange={(e) =>
            handleDateChange(rangeNames?.to || "", e.target.value)
          }
        />
      </div>
    </div>
  );

  const headerButtons = (searchQuery ||
    isFiltersEmpty ||
    (startDate && endDate) ||
    buttonText) && (
    <div className="d-flex flex-wrap gap-2">
      {(searchQuery || isFiltersEmpty || startDate || endDate) && (
        <button
          className="btn btn-text text-danger"
          onClick={handleClearFilters}
        >
          <IconTrash className="icon" />
          Очистить фильтры
        </button>
      )}
      {buttonText && (
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target={buttonModal}
        >
          <IconPlus className="icon" />
          {buttonText}
        </button>
      )}
      {getExcel && (
        <GetExcelBtn
          params={excelParams || new URLSearchParams()}
          count={excelCount || 0}
        />
      )}
    </div>
  );

  return (
    <div className="page">
      <NavbarContainer />
      <div className="page-wrapper">
        {header && (
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row gap-2 align-items-center">
                <div className="col-auto">
                  <h2 className="page-title d-block">{header}</h2>
                  <div className="text-muted mt-1">
                    {subheaderText || ""}
                    {isLoading ? <CountSkeleton /> : subheaderCount || 0}
                  </div>
                </div>

                {(search || buttonText || dateRange) && (
                  <div className="d-flex flex-grow-1 flex-wrap justify-content-end gap-2 col-auto">
                    {filters && headerFilters}
                    {search && headerSearchInput}
                    {dateRange && headerDateRange}
                    {headerButtons}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!isLoading && customHeader && customHeader}

        <div className="page-body">
          <div className="container-xl">
            {isLoading ? (
              <TableSkeleton />
            ) : dataLength ? (
              children
            ) : (
              <BlankSearch />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
