import { Api } from "api";

const endpoint = "/mobile_client_versions/v1/admin/";

export const getVersions = async (page?: number) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(endpoint, {
    params: {
      page: page,
    },
    headers,
  });
  return res.data;
};

export const addVersion = async (payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.post(endpoint, payload, { headers });
  return res.data;
};

export const editVersion = async (id: string, payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.put(endpoint + id, payload, { headers });
  return res.data;
};

export const deleteVersion = async (id: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.delete(endpoint + id, { headers });
  return res.data;
};
