import { useEffect, useState } from "react";
import { UserType, UsersResponseType, emptyUser } from "types/user-types";
import { getUsers, makeAdmin } from "api/users";
import { useSharedState } from "sharedStateProvider";
import { IconUser, IconX } from "@tabler/icons-react";
import { emptyPagination } from "types/pagination-types";
import RemoveNonNumericCharacters from "components/helpers/remove-non-numeric";
import TableSkeleton from "components/tables/table-skeleton";
import BlankSearch from "components/blank-pages/blank-search";

type AdminForm = {
  user_id: string;
  user_type: string;
  email: string;
  password: string;
};

const emptyAdmin: AdminForm = {
  user_id: "",
  user_type: "",
  email: "",
  password: "",
};

const adminTypes = [
  { name: "Выберите тип", value: "" },
  { name: "admin", value: "admin" },
  { name: "superadmin", value: "superadmin" },
];

export default function NewAdmin({ updateUsers }: { updateUsers: () => void }) {
  const { notify } = useSharedState();

  const searchParams = new URLSearchParams();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [users, setUsers] = useState<UsersResponseType>(emptyPagination);
  const [selectedUser, setSelectedUser] = useState<UserType>(emptyUser);
  const [adminForm, setAdminForm] = useState<AdminForm>(emptyAdmin);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [timeoutId, setTimeoutId] = useState<number | null>(null);

  const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const handleChange = (key: string, value: string) => {
    setAdminForm((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchQuery(inputValue);

    if (searchParams.has("search")) {
      searchParams.set("search", inputValue);
    } else {
      searchParams.append("search", inputValue);
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = window.setTimeout(() => {
      getAndSetUsers();
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const handleUserSelect = (user: UserType) => {
    if (selectedUser.id === user.id) {
      setSelectedUser(emptyUser);
    } else {
      setSelectedUser(user);
    }
  };

  const getAndSetUsers = async () => {
    setIsLoading(true);
    setUsers(emptyPagination);

    getUsers(searchParams)
      .then((res: UsersResponseType) => {
        setUsers(res);
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при загрузке пользователей");
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });
  };

  const clearSearch = () => {
    setSearchQuery("");
    searchParams.delete("search");
  };

  const cancelAdminCreation = () => {
    let allInputs = document.querySelectorAll("input");
    allInputs.forEach((singleInput) => {
      singleInput.value = "";
      singleInput.checked = false;
    });
    clearSearch();
    setUsers(emptyPagination);
    setSelectedUser(emptyUser);
  };

  const submitAdmin = async () => {
    makeAdmin(adminForm)
      .then(() => {
        notify("Администратор успешно добавлен", "success");
        updateUsers();
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при добавлении администратора");
      })
      .finally(() => cancelAdminCreation());
  };

  useEffect(() => {
    setAdminForm({
      user_id: selectedUser.id,
      user_type: "",
      email: "",
      password: "",
    });
  }, [selectedUser]);

  const usersList = (
    <div className="list-group list-group-flush">
      {(selectedUser.id ? [selectedUser] : users.data.slice(0, 10)).map(
        (user: UserType, index: number) => (
          <div
            className={`list-group-item cursor-pointer ${
              selectedUser.id === user.id && "active"
            }`}
            key={index}
            onClick={() => handleUserSelect(user)}
          >
            <div className="row align-items-center">
              <div className="col-auto">
                <input
                  type="checkbox"
                  className="form-check-input cursor-pointer"
                  checked={selectedUser.id === user.id}
                  onChange={(e) => handleUserSelect(user)}
                />
              </div>
              <div className="col-auto">
                {user.avatar ? (
                  <span
                    className="avatar me-2"
                    style={{
                      backgroundImage: `url(${user.avatar})`,
                    }}
                  ></span>
                ) : (
                  <span className="avatar me-2">
                    <IconUser />
                  </span>
                )}
              </div>
              <div className="col text-truncate">
                <div className="font-weight-medium">
                  {user.first_name} {user.last_name}
                </div>
                {user.phone ? (
                  <div className="text-muted">
                    <div className="text-reset">
                      +{RemoveNonNumericCharacters(user.phone)}
                    </div>
                  </div>
                ) : (
                  <div className="text-muted">Нет номера</div>
                )}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );

  return (
    <div
      className="modal modal-blur fade"
      id="modal-new-admin"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Добавить администратора</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={cancelAdminCreation}
            ></button>
          </div>
          <div className="modal-body">
            <div className="col-12">
              <div className="mb-3">
                <div className="input-group input-group-flat me-3">
                  <input
                    className="form-control d-inline-block w-9"
                    type="search"
                    placeholder="Поиск"
                    value={searchQuery ? searchQuery : ""}
                    onChange={handleSearchChange}
                  />
                  <span className="input-group-text">
                    {searchQuery && (
                      <div
                        className="link-secondary cursor-pointer"
                        onClick={clearSearch}
                      >
                        <IconX className="icon" />
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card mb-3">
                {isLoading ? (
                  <TableSkeleton />
                ) : users.data.length ? (
                  usersList
                ) : searchQuery ? (
                  <BlankSearch />
                ) : (
                  <div className="empty text-muted">
                    Воспользуйтесь поиском чтобы добавить администратора
                  </div>
                )}
              </div>
            </div>

            {selectedUser.id && (
              <div className="row">
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label className="form-label">Тип администратора</label>

                    <select
                      className="form-select"
                      value={adminForm.user_type}
                      onChange={(e) =>
                        handleChange("user_type", e.target.value)
                      }
                    >
                      {adminTypes.map((option, index) => (
                        <option
                          key={index}
                          value={option.value}
                          disabled={option === adminTypes[0]}
                        >
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label className="form-label">Электронная почта</label>
                    <input
                      type="email"
                      className={`form-control ${
                        !adminForm.email || emailRegEx.test(adminForm.email)
                          ? ""
                          : "is-invalid"
                      }`}
                      placeholder="Эл. почта"
                      value={adminForm.email}
                      onChange={(event) => {
                        handleChange("email", event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label className="form-label">
                      Пароль (мин. 8 символов)
                    </label>
                    <input
                      type="password"
                      className={`form-control ${
                        !adminForm.password || adminForm.password.length === 8
                          ? ""
                          : "is-invalid"
                      }`}
                      placeholder="Пароль"
                      value={adminForm.password}
                      onChange={(event) => {
                        handleChange("password", event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <div
              className="btn btn-link link-secondary"
              data-bs-dismiss="modal"
              onClick={cancelAdminCreation}
            >
              Отмена
            </div>
            <button
              className="btn btn-primary ms-auto"
              data-bs-dismiss="modal"
              onClick={submitAdmin}
              disabled={
                adminForm.user_type === adminTypes[0].value ||
                !emailRegEx.test(adminForm.email) ||
                adminForm.password.length < 8
              }
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
