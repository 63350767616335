import { Api } from "api";

export const getMiniAppNotifications = async () => {
  let url = "/notifications/v1/admin/mini_app_notifications";
  let accessToken = localStorage.getItem("accessToken");
  const headers = { Authorization: `Bearer ${accessToken}` };
  const res = await Api.get(url, { headers });
  return res.data;
};

export const getNotificationsEnabledByUsers = async (searchParams: URLSearchParams) => {
  let url = "/notifications/v1/admin/users_notifications";
  let params = searchParams ? `?${searchParams}` : "";
  let accessToken = localStorage.getItem("accessToken");
  const headers = { Authorization: `Bearer ${accessToken}` };
  const res = await Api.get(`${url}${params}`, { headers });
  return res.data;
};
