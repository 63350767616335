import { useEffect, useState } from "react";
import { useSharedState } from "sharedStateProvider";
import { BridgeMethodType, emptyBridgeMethod } from "types/mini-app-types";
import PageLayout from "components/page-layout";
import Table, { tableHeader } from "components/tables/table";
import { oneLine, mutedLine } from "components/tables/table-parts";
import NewBridgeMethod from "components/modals/new-bridge-method";
import DangerModal from "components/modals/danger";
import { deleteBridgeMethod } from "api/bridge";
import ActionButton from "components/tables/action-btn";
import { IconPencil, IconTrash } from "@tabler/icons-react";

export default function BridgeMethods() {
  const { bridgeMethods, getAndSetBridgeMethods, notify } = useSharedState();

  const [firstEntry, setFirstEntry] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editingBridge, setEditingBridge] = useState<BridgeMethodType>(emptyBridgeMethod);
  const [deletingMethodName, setDeletingMethodName] = useState<string>("");
  const [dangerDescription, setDangerDescription] = useState<string>("");
  const dangerText = "Вы действительно хотите удалить метод";

  const updateFirstEntry = (value: number) => {
    setFirstEntry(value);
  };

  const editUserData = (method: BridgeMethodType) => {
    setEditingBridge(method);
  };

  const clearBridgeData = () => {
    setEditingBridge(emptyBridgeMethod);
  };

  const onDangerModal = (method: BridgeMethodType) => {
    setDeletingMethodName(method.name);
    setDangerDescription(dangerText + ` "${method.name}"?`);
  };

  const deleteMethod = async () => {
    deleteBridgeMethod(deletingMethodName)
      .then(() => {
        notify("Метод успешно удалён", "success");
        initBridgePage();
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при удалении метода");
      });
  };

  const initBridgePage = async () => {
    setIsLoading(true);
    await getAndSetBridgeMethods();
    setIsLoading(false);
  };

  useEffect(() => {
    initBridgePage();
  }, []);

  const bridgeMethodsTableHeaders: tableHeader[] = [
    { header_text: "№", narrow: false, wrap: false },
    { header_text: "Название", narrow: false, wrap: false },
    { header_text: "Описание", narrow: false, wrap: false },
    { header_text: "", narrow: true, wrap: false },
  ];

  const bridgeMethodsList = (
    <>
      {bridgeMethods.data.map((method, index) => (
        <tr key={index}>
          <td>{oneLine((firstEntry + index).toString())}</td>
          <td>{oneLine(method.name)}</td>
          <td>{mutedLine(method.description)}</td>
          <td>
            <div className="btn-list flex-nowrap">
              <ActionButton
                icon={<IconPencil className="icon" />}
                text="Редактировать"
                action={() => editUserData(method)}
                color={"info"}
                modal={true}
                modal_id="#modal-new-bridge"
              />
              <ActionButton
                icon={<IconTrash className="icon" />}
                text="Удалить"
                action={() => onDangerModal(method)}
                color={"danger"}
                modal={true}
                modal_id="#modal-danger"
              />
            </div>
          </td>
        </tr>
      ))}
    </>
  );

  const bridgeMethodsTable = Table(
    bridgeMethodsTableHeaders,
    bridgeMethodsList,
    true,
    bridgeMethods,
    updateFirstEntry,
    getAndSetBridgeMethods
  );

  const bridgeMethodsHeader = "Методы бриджа";
  const bridgeMethodsSubheaderText = "Количество методов: ";
  const bridgeMethodsSubheaderCount = bridgeMethods.total;
  const headerButtonText = "Создать метод";
  const headerButtonModal = "#modal-new-bridge";

  return (
    <>
      <PageLayout
        children={bridgeMethodsTable}
        isLoading={isLoading}
        filters={false}
        search={false}
        header={bridgeMethodsHeader}
        subheaderText={bridgeMethodsSubheaderText}
        subheaderCount={bridgeMethodsSubheaderCount}
        dataLength={bridgeMethods.data.length}
        buttonText={headerButtonText}
        buttonModal={headerButtonModal}
        dateRange={false}
      />
      <NewBridgeMethod bridgeData={editingBridge} clearBridgeData={clearBridgeData} />
      <DangerModal dangerDescription={dangerDescription} confirm={deleteMethod} />
    </>
  );
}
