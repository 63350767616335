import { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import InputMask from "react-input-mask";
import { UserType, UserTypeDict, emptyUser } from "types/user-types";
import { addUser, editUser } from "api/users";
import RemoveNonNumericCharacters from "components/helpers/remove-non-numeric";
import { useSharedState } from "sharedStateProvider";

export default function NewUser({
  userData,
  updateUsers,
  cancelEdit,
}: {
  userData: UserType;
  updateUsers: () => void;
  cancelEdit?: (value: UserType) => void;
}) {
  const { notify } = useSharedState();

  const location = useLocation();

  const isAdminPath = useMemo(
    () => location.pathname === "/admins",
    [location.pathname]
  );

  const [userForm, setUserForm] = useState<UserType>(emptyUser);

  const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const handleChange = (key: string, value: string) => {
    setUserForm((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };
  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedPhoneNumber = RemoveNonNumericCharacters(event.target.value);
    handleChange("phone", cleanedPhoneNumber);
  };

  const cancelUserCreation = () => {
    let allInputs = document.querySelectorAll("input");
    allInputs.forEach((singleInput) => {
      singleInput.value = "";
      singleInput.checked = false;
    });
    setUserForm(emptyUser);
    cancelEdit && cancelEdit(emptyUser);
  };

  const submitUser = async () => {
    if (userData.first_name) {
      editUser(userData.id, userForm)
        .then(() => {
          notify("Данные пользователя сохранены", "success");
          updateUsers();
        })
        .catch((error: any) => {
          let err = error.response.data.error;
          notify(err, "danger", "Ошибка при редактировании пользователя");
        })
        .finally(() => cancelUserCreation());
    } else {
      addUser(userForm)
        .then(() => {
          notify("Пользователь успешно создан", "success");
          updateUsers();
        })
        .catch((error: any) => {
          let err = error.response.data.error;
          notify(err, "danger", "Ошибка при создании пользователя");
        })
        .finally(() => cancelUserCreation());
    }
  };

  useEffect(() => {
    setUserForm({
      id: userData.id,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      middle_name: userData?.middle_name,
      avatar: userData?.avatar,
      avatar_thumb: userData?.avatar_thumb,
      phone: userData?.phone,
      iin: userData?.iin,
      birth_date: userData?.birth_date,
      gender: userData?.gender,
      ap_id: userData?.ap_id,
      status: userData?.status,
      user_type: userData?.user_type,
      enterprise: userData?.enterprise,
      org_unit: userData?.org_unit,
      position: userData?.position,
      email: userData.email,
      password: userData.password,
      updated_at: userData.created_at,
      created_at: userData.updated_at,
    });
  }, [userData]);

  return (
    <div
      className="modal modal-blur fade"
      id="modal-new-user"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {userData.id
                ? "Редактирование пользователя"
                : "Новый пользователь"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={cancelUserCreation}
            ></button>
          </div>
          <div className="modal-body">
            {userForm.id && (
              <div className="row">
                <div className="col-lg-8">
                  <div className="mb-3">
                    <label className="form-label">ID пользователя</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ID пользователя"
                      value={userForm.id}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label className="form-label">Aitu Passport ID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Aitu Passport ID"
                      value={userForm.ap_id}
                      disabled
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label required">Имя</label>
                  <input
                    type="text"
                    className="form-control"
                    // className={`form-control ${userForm.first_name ? 'is-valid' : 'is-invalid'}`}
                    placeholder="Имя"
                    value={userForm.first_name}
                    onChange={(event) => {
                      handleChange("first_name", event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label required">Фамилия</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Фамилия"
                    value={userForm.last_name}
                    onChange={(event) => {
                      handleChange("last_name", event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Отчество</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Отчество"
                    value={userForm.middle_name}
                    onChange={(event) => {
                      handleChange("middle_name", event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label required">Дата рождения</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="ДД.ММ.ГГГГ"
                    max="9999-12-31"
                    value={userForm.birth_date}
                    onChange={(event) => {
                      handleChange("birth_date", event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label required">ИИН</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="ИИН"
                    value={userForm.iin}
                    onChange={(event) => {
                      handleChange("iin", event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label required">Номер телефона</label>
                  <div className="input-group input-group-flat">
                    <InputMask
                      className="form-control"
                      type="tel"
                      placeholder="+7-(___)-___-__-__"
                      mask="+7-(999)-999-99-99"
                      maskChar="_"
                      value={userForm.phone}
                      onChange={onPhoneChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label">Пол</label>
              <div className="col-lg-6">
                <label className="form-selectgroup-item">
                  <input
                    type="radio"
                    name="report-type"
                    className="form-selectgroup-input"
                    value="MALE"
                    checked={userForm.gender === "MALE"}
                    onChange={(event) => {
                      handleChange("gender", event.target.value);
                    }}
                  />
                  <span className="form-selectgroup-label d-flex align-items-center p-3">
                    <span className="me-3">
                      <span className="form-selectgroup-check"></span>
                    </span>
                    <span className="form-selectgroup-label-content">
                      <span className="form-selectgroup-title strong mb-1">
                        Мужской
                      </span>
                    </span>
                  </span>
                </label>
              </div>
              <div className="col-lg-6">
                <label className="form-selectgroup-item">
                  <input
                    type="radio"
                    className="form-selectgroup-input"
                    value="FEMALE"
                    checked={userForm.gender === "FEMALE"}
                    onChange={(event) => {
                      handleChange("gender", event.target.value);
                    }}
                  />
                  <span className="form-selectgroup-label d-flex align-items-center p-3">
                    <span className="me-3">
                      <span className="form-selectgroup-check"></span>
                    </span>
                    <span className="form-selectgroup-label-content">
                      <span className="form-selectgroup-title strong mb-1">
                        Женский
                      </span>
                    </span>
                  </span>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label required">
                    Тип пользователя
                  </label>
                  <select
                    className="form-select"
                    id="select-users"
                    value={userForm.user_type}
                    onChange={(event) => {
                      handleChange("user_type", event.target.value);
                    }}
                  >
                    {UserTypeDict.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {userForm.id && (
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label">Предприятие</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Предприятие"
                      value={userForm.enterprise}
                      disabled
                    />
                  </div>
                </div>
              )}
            </div>
            {userForm.id && (
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label">Подразделение</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Подразделение"
                      value={userForm.org_unit}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label">Должность</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Должность"
                      value={userForm.position}
                      disabled
                    />
                  </div>
                </div>
              </div>
            )}
            {isAdminPath && (
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label">Электронная почта</label>
                    <input
                      type="email"
                      className={`form-control ${
                        !userForm?.email ||
                        emailRegEx.test(userForm?.email || "")
                          ? ""
                          : "is-invalid"
                      }`}
                      placeholder="Эл. почта"
                      value={userForm.email}
                      onChange={(event) => {
                        handleChange("email", event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label">Пароль</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Пароль"
                      value={userForm.password}
                      onChange={(event) => {
                        handleChange("password", event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-link link-secondary"
              type="reset"
              data-bs-dismiss="modal"
              onClick={cancelUserCreation}
            >
              Отмена
            </button>
            <button
              className="btn btn-primary ms-auto"
              type="submit"
              data-bs-dismiss="modal"
              onClick={submitUser}
              disabled={
                !userForm.first_name ||
                !userForm.last_name ||
                !userForm.birth_date ||
                !userForm.iin ||
                userForm.phone.length < 11 ||
                (isAdminPath && userForm?.email
                  ? !emailRegEx.test(userForm?.email || "")
                  : false)
              }
            >
              {userData.id ? "Сохранить" : "Создать пользователя"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
