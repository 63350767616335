import React from "react";
import { dynamicColor } from "components/tables/table-parts";

interface ActionButtonProps {
  icon?: React.JSX.Element;
  text?: string;
  tooltip?: string;
  color: string;
  action: () => void;
  modal: boolean;
  modal_id?: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({ icon, text, tooltip, color, action, modal, modal_id }) => {
  const modalBtnProps = {
    "data-bs-toggle": "modal",
    "data-bs-target": modal_id,
  };

  const additionalBtnProps = modal ? modalBtnProps : {};

  return (
    <button
      className={`btn ${text ? undefined : "btn-icon"} ${dynamicColor(color)}`}
      title={tooltip}
      onClick={action}
      {...additionalBtnProps}
    >
      {icon}
      {text}
    </button>
  );
};

export default ActionButton;
