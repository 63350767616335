import axios from "axios";
import { refreshToken } from "./auth";

const ApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Accept-Language": "ru",
  },
});

// Intercepting token
let isRefreshing = false;
let failedQueue: Array<{
  resolve: (value: string) => void;
  reject: (reason?: any) => void;
}> = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token!);
    }
  });

  failedQueue = [];
};

ApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error: any) {
    if (error.config.url.includes("/refresh")) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      window.location.href = "/";
      return Promise.reject(error);
    }

    const originalRequest: any = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return ApiInstance(originalRequest);
          })
          .catch((err: any) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        refreshToken()
          .then((res: any) => {
            localStorage.setItem("accessToken", res.access.hash);
            localStorage.setItem("refreshToken", res.refresh.hash);
            originalRequest.headers["Authorization"] =
              "Bearer " + res.access.hash;
            processQueue(null, res.access.hash);
            resolve(ApiInstance(originalRequest));
          })
          .catch((err: any) => {
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default ApiInstance;
export { ApiInstance as Api };
