export default function BlankSearch() {
  return (
    <div className="empty">
      <p className="empty-title">Ничего не найдено</p>
      <p className="empty-subtitle text-muted">
        Извините, но по вашему запросу ничего не найдено.
      </p>
    </div>
  );
}
