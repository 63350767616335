import { useEffect, useState, useMemo } from "react";
import { useSharedState } from "sharedStateProvider";
import InputMask from "react-input-mask";
import {
  MiniAppFormType,
  MiniAppType,
  emptyApp,
  emptyAppForm,
  BridgeMethodResponseType,
  BridgeMethodType,
  MiniAppPayloadType,
} from "types/mini-app-types";
import { getMiniApp, createMiniApp, putMiniApp, prepareLogoUpload, uploadLogo } from "api/mini-apps";
import { getBridgeMethods } from "api/bridge";
import { IconAlertTriangle, IconApps, IconPlus, IconUser, IconX } from "@tabler/icons-react";
import { getCollaborators, addCollaborators, deleteCollaborators, findUser } from "api/collaborators";
import { CollaboratorType } from "types/collaborator-type";
import RemoveNonNumericCharacters from "components/helpers/remove-non-numeric";
import { sha1 } from "js-sha1";
import { md5 } from "js-md5";

export default function NewMiniApp({
  isReadingApp,
  appId,
  updateMiniApps,
  clearAppId,
  toggleAppEdit,
}: {
  isReadingApp: boolean;
  appId: string;
  updateMiniApps: () => void;
  clearAppId: () => void;
  toggleAppEdit: (value: boolean) => void;
}) {
  const { isSuperAdmin, notify } = useSharedState();

  const [editMiniApp, setEditMiniApp] = useState<MiniAppType>(emptyApp);
  const [bridgeMethods, setBridgeMethods] = useState<BridgeMethodType[]>([]);
  const [appForm, setAppForm] = useState<MiniAppFormType>(emptyAppForm);
  const [selectedBridgeMethods, setSelectedBridgeMethods] = useState<string[]>([]);
  const [changeLogo, setChangeLogo] = useState<boolean>(false);
  const [filePreview, setFilePreview] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);

  const [collaborators, setCollaborators] = useState<CollaboratorType[]>();
  const [isAddingCollab, setIsAddingCollab] = useState<boolean>(false);
  const [deletingCollab, setDeletingCollab] = useState<string>("");
  const [collabPhone, setCollabPhone] = useState<string>("");
  const [collabError, setCollabError] = useState<string>("");

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedPhoneNumber = RemoveNonNumericCharacters(event.target.value);
    setCollabPhone(cleanedPhoneNumber);
    collabError && setCollabError("");
  };

  const handleChange = (key: string, value: string) => {
    setAppForm((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleBridgeSelection = (value: string) => {
    if (selectedBridgeMethods.includes(value)) {
      setSelectedBridgeMethods(selectedBridgeMethods.filter((method) => method !== value));
    } else {
      setSelectedBridgeMethods([...selectedBridgeMethods, value]);
    }
  };

  const handleLogoChange = () => {
    if (!changeLogo) {
      setChangeLogo(true);
    } else {
      clearLogoChange();
    }
  };

  const handleLogoInput = async (event: any) => {
    console.log("event", event);

    // Your custom logic to determine whether to allow the upload
    const isJPG = event.target.files[0].type === "image/jpeg";
    if (!isJPG) {
      return;
    } else {
      // Trigger the preview logic manually
      const previewUrl = await customPreviewFile(event.target.files[0]);
      setFilePreview(previewUrl);
      setFile(event.target.files[0]);
    }
  };

  const customPreviewFile = async (file: File | Blob): Promise<string> => {
    // Your custom preview logic here
    const previewUrl = await getCustomPreviewUrl(file);
    return previewUrl;
  };

  const getCustomPreviewUrl = (file: File | Blob): Promise<string> => {
    // Your custom logic to generate a preview URL
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const getLogoLink = async () => {
    // @ts-ignore
    return new Promise((resolve, reject) => {
      if (file != null) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          if (e.target && e.target.result) {
            const fileContent = e.target.result;
            // @ts-ignore
            const md5Result = md5(fileContent);
            // @ts-ignore
            const sha1Result = sha1(fileContent);
            const logoInfo: object = {
              filename: file.name,
              md5: md5Result,
              sha1: sha1Result,
              public: true,
              type: file.type,
            };
            const res: object = await prepareLogoUpload(logoInfo);
            if (res.hasOwnProperty("id")) {
              //file exists
              // @ts-ignore
              resolve(res.id);
            } else {
              // @ts-ignore
              const url = new URL(res.upload_url, "http://localhost");
              const p = url.searchParams;
              const fd = new FormData();
              // @ts-ignore
              fd.append("file", fileList[0].originFileObj);
              const query =
                `?signature=${p.get("signature")}&expires_at=${p.get("expires_at")}` +
                `&filename=${p.get("filename")}&sha1=${p.get("sha1")}&md5=${p.get("md5")}&file_type=${p.get(
                  "file_type"
                )}&public=${p.get("public")}`;
              const uploadRes: object = await uploadLogo(query, fd);
              if (uploadRes.hasOwnProperty("id")) {
                // @ts-ignore
                resolve(uploadRes.id);
              } else {
                reject("");
              }
            }
          }
        };
        // @ts-ignore
        reader.readAsArrayBuffer(file);
      } else {
        throw new Error("File is null");
      }
    }).catch((error: any) => {
      let err = error.response.data.error;
      notify(err, "danger", "Не удалось загрузить логотип");
    });
  };

  const handleUploadLogo = async () => {
    if (file) {
      return getLogoLink();
    } else {
      if (!editMiniApp.id) {
        return appForm.logo;
      } else {
        return editMiniApp.logo;
      }
    }
  };

  const isValidUrl = (): boolean => {
    const pattern = new RegExp(
      "^(https:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(appForm.link);
  };

  const isFormValid: boolean = useMemo(() => {
    if (
      appForm.nameRu &&
      appForm.nameKz &&
      appForm.nameEn &&
      appForm.descriptionRu &&
      appForm.descriptionKz &&
      appForm.descriptionEn &&
      appForm.link.includes("https://") &&
      isValidUrl()
    )
      return true;
    return false;
  }, [appForm]);

  const submitApp = async () => {
    // @ts-ignore
    const logo: string = await handleUploadLogo();

    const newAppInfo: MiniAppPayloadType = {
      names: {
        ru: appForm.nameRu,
        kz: appForm.nameKz,
        en: appForm.nameEn,
      },
      descriptions: {
        ru: appForm.descriptionRu,
        kz: appForm.descriptionKz,
        en: appForm.descriptionEn,
      },
      bridge_methods: selectedBridgeMethods,
      link: appForm.link,
      logo: logo || appForm.logo,
    };

    if (editMiniApp.id) {
      putMiniApp(editMiniApp.id, newAppInfo)
        .then(() => {
          notify("Мини-приложение успешно изменено", "success");
          updateMiniApps();
        })
        .catch((error: any) => {
          let err = error.response.data.error;
          notify(err, "danger", "Ошибка при редактировании мини-приложения");
        })
        .finally(() => cancelAppCreation());
    } else {
      createMiniApp(newAppInfo)
        .then(() => {
          notify("Мини-приложение успешно создано", "success");
          updateMiniApps();
        })
        .catch((error: any) => {
          let err = error.response.data.error;
          notify(err, "danger", "Ошибка при создании мини-приложения");
        })
        .finally(() => cancelAppCreation());
    }
  };

  const toggleCollabAdding = () => {
    if (isAddingCollab) {
      setCollabPhone("");
      setCollabError("");
      setIsAddingCollab(false);
    } else {
      setIsAddingCollab(true);
    }
  };

  const disableCollabAdding = () => {
    setCollabPhone("");
    setCollabError("");
    setIsAddingCollab(false);
  };

  const addCollaborator = async () => {
    findUser(collabPhone)
      .then((USER: CollaboratorType) => {
        addCollaborators(editMiniApp.id, { collaborators: [USER.id] })
          .then(() => {
            notify("Коллаборатор успешно добавлен", "success");
            getAndSetCollaborators();
            disableCollabAdding();
          })
          .catch((error: any) => {
            let err = error.response.data.error;
            setCollabError(err || "Ошибка при добавлении коллаборатора");
            notify(err, "danger", "Ошибка при добавлении коллаборатора");
          });
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        setCollabError(err || "Ошибка при добавлении коллаборатора");
        notify(err, "danger", "Ошибка при добавлении коллаборатора");
      });
  };

  const onDeleteCollaborator = async (user: CollaboratorType) => {
    deleteCollaborators(editMiniApp.id, { collaborators: [user.id] })
      .then(() => {
        notify("Коллаборатор успешно удалён", "success");
        getAndSetCollaborators();
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при удалении коллаборатора");
      })
      .finally(() => {
        setDeletingCollab("");
      });
  };

  const clearLogoChange = () => {
    setChangeLogo(false);
    setFilePreview("");
    setFile(null);
  };

  const cancelAppCreation = () => {
    setEditMiniApp(emptyApp);
    setAppForm(emptyAppForm);
    setSelectedBridgeMethods([]);
    clearAppId();
    clearLogoChange();
    let allInputs = document.querySelectorAll("input");
    allInputs.forEach((singleInput) => {
      singleInput.value = "";
    });
    disableCollabAdding();
    setDeletingCollab("");
  };

  const getAndSetMiniApp = async () => {
    getMiniApp(appId)
      .then((res: MiniAppType) => {
        setEditMiniApp(res);
        setAppForm({
          nameRu: res.names.ru,
          nameKz: res.names.kz,
          nameEn: res.names.en,
          descriptionRu: res.descriptions.ru,
          descriptionKz: res.descriptions.kz,
          descriptionEn: res.descriptions.en,
          bridgeMethods: res.bridge_methods,
          link: res.link,
          logo: res.logo,
          logo_url: res.logo_url,
        });
        setFilePreview(res.logo_url);
        setSelectedBridgeMethods(res.bridge_methods || []);
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении мини-приложения");
      });
  };

  const initBridgeMethods = async () => {
    try {
      const firstPageData: BridgeMethodResponseType = await getBridgeMethods();
      const totalPages = Math.ceil(firstPageData.total / firstPageData.size);

      const pageDataPromises = [];
      for (let i = 2; i <= totalPages; i++) {
        let response: BridgeMethodResponseType = await getBridgeMethods(i);
        pageDataPromises.push(response.data);
      }

      const otherPagesData = await Promise.all(pageDataPromises);
      const allData = [firstPageData.data, ...otherPagesData].flat();

      setBridgeMethods(allData);
    } catch (error: any) {
      let err = error.response.data.error;
      notify(err, "danger", "Ошибка при получении бридж-методов");
    }
  };

  const getAndSetCollaborators = async () => {
    getCollaborators(appId)
      .then((res: CollaboratorType[]) => {
        setCollaborators(res);
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении коллабораторов");
      });
  };

  useEffect(() => {
    appId && Promise.all([getAndSetMiniApp(), getAndSetCollaborators()]);
  }, [appId]);

  useEffect(() => {
    initBridgeMethods();
  }, []);

  return (
    <div className="modal modal-blur fade" id="modal-new-mini-app" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {isReadingApp ? editMiniApp.names.ru : editMiniApp.id ? "Редактировать" : "Новое мини-приложение"}
              {editMiniApp.id && <span className="card-subtitle ms-lg-2">ID: {editMiniApp.id}</span>}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={cancelAppCreation}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row row-cards mb-3">
              <div className="col-md-4">
                <div className="mb-3">
                  <label className="form-label required">Название на русском</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Название на русском"
                    value={appForm.nameRu}
                    onChange={(event) => {
                      handleChange("nameRu", event.target.value);
                    }}
                    disabled={isReadingApp}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label className="form-label required">Название на казахском</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Название на казахском"
                    value={appForm.nameKz}
                    onChange={(event) => {
                      handleChange("nameKz", event.target.value);
                    }}
                    disabled={isReadingApp}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label className="form-label required">Название на английском</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Название на английском"
                    value={appForm.nameEn}
                    onChange={(event) => {
                      handleChange("nameEn", event.target.value);
                    }}
                    disabled={isReadingApp}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label className="form-label required">Описание на русском</label>
                  <textarea
                    rows={5}
                    className="form-control form-textarea-no-resize"
                    placeholder="Описание на русском"
                    value={appForm.descriptionRu}
                    onChange={(event) => {
                      handleChange("descriptionRu", event.target.value);
                    }}
                    disabled={isReadingApp}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label className="form-label required">Описание на казахском</label>
                  <textarea
                    rows={5}
                    className="form-control form-textarea-no-resize"
                    placeholder="Описание на казахском"
                    value={appForm.descriptionKz}
                    onChange={(event) => {
                      handleChange("descriptionKz", event.target.value);
                    }}
                    disabled={isReadingApp}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label className="form-label required">Описание на английском</label>
                  <textarea
                    rows={5}
                    className="form-control form-textarea-no-resize"
                    placeholder="Описание на английском"
                    value={appForm.descriptionEn}
                    onChange={(event) => {
                      handleChange("descriptionEn", event.target.value);
                    }}
                    disabled={isReadingApp}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label className="form-label required">Адрес приложения (https:// - обязательно)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="URL приложения"
                    value={appForm.link}
                    onChange={(event) => {
                      handleChange("link", event.target.value);
                    }}
                    disabled={isReadingApp}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Лого приложения</label>
                  {!isReadingApp && (
                    <div className="text-muted mb-1">
                      Формат: Квадратный, максимальный размер 640x640px. Только JPG/JPEG
                    </div>
                  )}
                  <div className="row d-flex">
                    {changeLogo ? (
                      <div className="col-md-4">
                        <label
                          className="avatar avatar-xl rounded cursor-pointer"
                          htmlFor="file"
                          style={{ backgroundImage: `url(${filePreview})` }}
                        >
                          {!file && <IconPlus className="icon" />}
                          <input id="file" type="file" hidden onChange={handleLogoInput} />
                        </label>
                      </div>
                    ) : (
                      <div className="col-md-4">
                        <span
                          className="avatar avatar-xl rounded"
                          style={{
                            backgroundImage: `url(${editMiniApp.id ? editMiniApp.logo_url : appForm.logo_url})`,
                          }}
                        >
                          {!appForm.logo_url && <IconApps />}
                        </span>
                      </div>
                    )}
                    {!isReadingApp && (
                      <div className="col-md-8 hstack">
                        <button className="btn" onClick={handleLogoChange}>
                          {changeLogo ? "Отмена" : "Изменить"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="mb-3">
                  <div className="form-label">Методы бриджа</div>
                  <div className="d-flex flex-wrap">
                    {bridgeMethods.map((method, index) => (
                      <label className="form-check col-6 col-xl-4" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selectedBridgeMethods.includes(method.name)}
                          onChange={() => handleBridgeSelection(method.name)}
                          disabled={isReadingApp}
                        />
                        <span className="form-check-label">{method.name}</span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              {editMiniApp.id && (
                <>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <div className="card">
                        <div className="card-header" style={{ height: 69 }}>
                          <h3 className="card-title">Владелец мини-приложения</h3>
                        </div>
                        <div className="list-group list-group-flush">
                          <div className="list-group-item">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <span
                                  className="avatar rounded"
                                  style={{
                                    backgroundImage: `url(${editMiniApp.user?.avatar})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    backgroundSize: "contain",
                                  }}
                                >
                                  {!editMiniApp.user?.avatar && <IconUser />}
                                </span>
                              </div>
                              <div className="col text-truncate">
                                <div>
                                  {editMiniApp.user?.name} {editMiniApp.user?.lastname}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Коллабораторы</h3>

                          <div className="col-auto ms-auto d-print-none">
                            <div className="d-flex">
                              <div className="btn btn-primary cursor-pointer" onClick={toggleCollabAdding}>
                                {isAddingCollab ? (
                                  <>
                                    <IconX className="icon" />
                                    Отмена
                                  </>
                                ) : (
                                  <>
                                    <IconPlus className="icon" />
                                    Добавить
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="list-group list-group-flush">
                          {collaborators?.map((collab, index) => (
                            <div className="list-group-item" key={index}>
                              <div className="row align-items-center">
                                <div className="col-auto">
                                  <span
                                    className="avatar rounded"
                                    style={{
                                      backgroundImage: `url(${collab.avatar})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                      backgroundSize: "contain",
                                    }}
                                  >
                                    {!collab.avatar && <IconUser />}
                                  </span>
                                </div>
                                <div className="col text-truncate">
                                  <div className="text-reset d-block">
                                    {collab.first_name} {collab.last_name}
                                  </div>
                                </div>
                                {!deletingCollab && (
                                  <div className="col-auto cursor-pointer" onClick={() => setDeletingCollab(collab.id)}>
                                    <IconX className="icon" />
                                  </div>
                                )}
                              </div>
                              {deletingCollab === collab.id && (
                                <div className="alert alert-warning mt-3" role="alert">
                                  <div className="d-flex gap-3 mb-3">
                                    <div>
                                      <IconAlertTriangle className="icon" />
                                    </div>
                                    <div>
                                      Вы действительно хотите удалить {collab.first_name} {collab.last_name} из списка
                                      коллабораторов?
                                    </div>
                                  </div>

                                  <div className="btn-list ms-auto justify-content-end">
                                    <div className="btn btn-link link-secondary" onClick={() => setDeletingCollab("")}>
                                      Отмена
                                    </div>
                                    <div className="btn btn-danger" onClick={() => onDeleteCollaborator(collab)}>
                                      Удалить
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                          {isAddingCollab && (
                            <div className="list-group-item">
                              <div className="mb-2">Добавление коллаборатора</div>
                              <div className="row">
                                <div className="col">
                                  <InputMask
                                    className="form-control"
                                    type="tel"
                                    placeholder="+7-(___)-___-__-__"
                                    mask="+7-(999)-999-99-99"
                                    maskChar="_"
                                    value={collabPhone}
                                    onChange={onPhoneChange}
                                  />
                                </div>

                                <div className="col-auto ms-auto d-print-none">
                                  <div className="d-flex">
                                    <div className="btn btn-primary cursor-pointer" onClick={addCollaborator}>
                                      <IconPlus className="icon" />
                                      Добавить
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {collabError && <div className="mt-2 text-danger">{collabError}</div>}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-link link-secondary"
              type="reset"
              data-bs-dismiss="modal"
              onClick={cancelAppCreation}
            >
              Отмена
            </button>
            {isReadingApp ? (
              isSuperAdmin && (
                <button className="btn btn-primary" onClick={() => toggleAppEdit(false)}>
                  Редактировать
                </button>
              )
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                data-bs-dismiss="modal"
                onClick={submitApp}
                disabled={!isFormValid}
              >
                {editMiniApp.id ? "Сохранить" : "Создать"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
