import { useEffect, useState } from "react";
import { useSharedState } from "sharedStateProvider";
import { BlankVersion, VersionType, VersionsResponseType } from "types/version-type";
import { emptyPagination } from "types/pagination-types";
import PageLayout from "components/page-layout";
import Table, { tableHeader } from "components/tables/table";
import { oneLine, checkOrXIcon } from "components/tables/table-parts";
import NewVersion from "components/modals/new-version";
import DangerModal from "components/modals/danger";
import { getVersions, deleteVersion } from "api/versions";
import ActionButton from "components/tables/action-btn";
import { IconPencil, IconTrash } from "@tabler/icons-react";

export default function Versions() {
  const { isSuperAdmin, notify } = useSharedState();

  const [versions, setVersions] = useState<VersionsResponseType>(emptyPagination);
  const [firstEntry, setFirstEntry] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateFirstEntry = (value: number) => {
    setFirstEntry(value);
  };

  const [editingVer, setEditingVer] = useState<VersionType>(BlankVersion);

  const [deletingVerisonId, setDeletingVerisonId] = useState<string>("");
  const [dangerDescription, setDangerDescription] = useState<string>("");
  const deleteVersionText = `Вы действительно хотите удалить версию`;

  const getAndSetVersions = async (page?: number) => {
    setIsLoading(true);
    getVersions(page)
      .then((res: VersionsResponseType) => {
        setVersions(res);
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при загрузке версий");
      })
      .finally(() => setIsLoading(false));
  };

  const editVersionData = (v: VersionType) => {
    setEditingVer(v);
  };

  const onDangerModal = (v: VersionType) => {
    setDeletingVerisonId(v.id?.toString() || "");
    setDangerDescription(deleteVersionText + ` "${v.major}.${v.minor}.${v.patch}"?`);
  };

  const confirmDanger = async () => {
    deleteVersion(deletingVerisonId)
      .then(() => {
        notify("Версия успешно удалена", "success");
        getAndSetVersions();
      })
      .catch((error) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при удалении версии");
      });
  };

  useEffect(() => {
    Promise.all([getAndSetVersions()]);
  }, []);

  const versionsTableHeaders: tableHeader[] = [
    { header_text: "№", narrow: true, wrap: true },
    { header_text: "ID", narrow: true, wrap: true },
    { header_text: "Версия", narrow: true, wrap: true },
    { header_text: "iOS", narrow: true, wrap: true },
    { header_text: "Android", narrow: true, wrap: true },
    { header_text: "Huawei", narrow: true, wrap: true },
    { header_text: "Необходимость", narrow: true, wrap: true },
    { header_text: "Примечание (RU)", narrow: false, wrap: true },
    { header_text: "Примечание (KZ)", narrow: false, wrap: true },
    { header_text: "Примечание (EN)", narrow: false, wrap: true },
    { header_text: "", narrow: true, wrap: true },
  ];

  const versionsList = (
    <>
      {versions.data.map((ver, index) => (
        <tr key={index}>
          <td>{oneLine((firstEntry + index).toString())}</td>
          <td>{oneLine(ver.id?.toString() || "-")}</td>
          <td>{oneLine(`${ver.major}.${ver.minor}.${ver.patch}`)}</td>
          <td>{checkOrXIcon(ver.ios_available)}</td>
          <td>{checkOrXIcon(ver.android_available)}</td>
          <td>{checkOrXIcon(ver.huawei_available)}</td>
          <td>{checkOrXIcon(ver.required)}</td>
          <td>{oneLine(ver.notes.ru)}</td>
          <td>{oneLine(ver.notes.kz)}</td>
          <td>{oneLine(ver.notes.eng)}</td>
          <td>
            <div className="btn-list flex-nowrap">
              <ActionButton
                icon={<IconPencil className="icon" />}
                tooltip="Изменить"
                action={() => editVersionData(ver)}
                color={"info"}
                modal={true}
                modal_id="#modal-add-version"
              />
              <ActionButton
                icon={<IconTrash className="icon" />}
                tooltip="Удалить"
                action={() => onDangerModal(ver)}
                color={"danger"}
                modal={true}
                modal_id="#modal-danger"
              />
            </div>
          </td>
        </tr>
      ))}
    </>
  );

  const versionsTable = Table(versionsTableHeaders, versionsList, true, versions, updateFirstEntry, getAndSetVersions);

  const versionsHeader = "Версии приложения";
  const versionsSubheaderText = "Количество версий: ";
  const versionsSubheaderCount = versions.total;
  const headerButtonText = "Добавить версию";
  const headerButtonModal = "#modal-add-version";

  return (
    <>
      <PageLayout
        children={versionsTable}
        isLoading={isLoading}
        filters={false}
        search={false}
        header={versionsHeader}
        subheaderText={versionsSubheaderText}
        subheaderCount={versionsSubheaderCount}
        dataLength={versions.data.length}
        buttonText={isSuperAdmin ? headerButtonText : undefined}
        buttonModal={isSuperAdmin ? headerButtonModal : undefined}
        dateRange={false}
      />
      <NewVersion verData={editingVer} updateVerList={getAndSetVersions} cancelEdit={editVersionData} />
      <DangerModal dangerDescription={dangerDescription} confirm={confirmDanger} />
    </>
  );
}
