export type VersionType = {
  id?: number;
  major: number | undefined;
  minor: number | undefined;
  patch: number | undefined;
  android_available: boolean;
  huawei_available: boolean;
  ios_available: boolean;
  required: boolean;
  notes: {
    ru: string;
    kz: string;
    eng: string;
  };
};

export type VersionsResponseType = {
  data: VersionType[];
  page: number;
  size: number;
  sort: string;
  total: number;
};

export type VersionFormType = {
  major: number | undefined;
  minor: number | undefined;
  patch: number | undefined;
  android_available: boolean;
  huawei_available: boolean;
  ios_available: boolean;
  required: boolean;
  notesRu: string;
  notesKz: string;
  notesEn: string;
};

export const BlankVersion = {
  major: undefined,
  minor: undefined,
  patch: undefined,
  android_available: false,
  huawei_available: false,
  ios_available: false,
  required: false,
  notes: {
    ru: "",
    kz: "",
    eng: "",
  },
};

export const BlankVersionForm = {
  major: undefined,
  minor: undefined,
  patch: undefined,
  android_available: false,
  huawei_available: false,
  ios_available: false,
  required: false,
  notesRu: "",
  notesKz: "",
  notesEn: "",
};
