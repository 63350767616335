export default function TableSkeleton() {
  return (
    <div className="col-12">
      <div className="card">
        <ul className="list-group list-group-flush placeholder-glow">
          {Array.from({ length: 10 }, (_, i) => i + 1).map((li: number) => (
            <li className="list-group-item" key={li}>
              <div className="row align-items-center">
                <div className="col-auto">
                  <div className="avatar avatar-rounded placeholder"></div>
                </div>
                <div className="col-7">
                  <div className="placeholder placeholder-xs col-9"></div>
                  <div className="placeholder placeholder-xs col-7"></div>
                </div>
                <div className="col-2 ms-auto text-end">
                  <div className="placeholder placeholder-xs col-8"></div>
                  <div className="placeholder placeholder-xs col-10"></div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
