import { useSharedState } from "sharedStateProvider";

export default function LogoHeader({ size }: { size: 0.5 | 1 | 1.5 | 2 }) {
  const { darkMode } = useSharedState();
  return (
    <svg
      width={72 * size}
      height={24 * size}
      viewBox="0 0 72 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3500_15159)">
        <path
          d="M56.1428 18.1513H51.6428V5.84875H56.1428V18.1513Z"
          fill={darkMode ? "#ffffff" : "#206bc4"}
        />
        <path
          d="M49.1014 18.0866C47.7514 18.0866 46.5514 17.8278 45.5014 17.3101C44.4614 16.7831 43.6414 16.0631 43.0414 15.1501C42.4514 14.2278 42.1564 13.1737 42.1564 11.9878C42.1564 10.8019 42.4514 9.74776 43.0414 8.82542C43.6414 7.89365 44.4614 7.15954 45.5014 6.62307C46.5514 6.08659 47.7514 5.81836 49.1014 5.81836C50.4514 5.81836 51.6415 6.08659 52.6714 6.62307C53.7014 7.15954 54.5065 7.89365 55.0864 8.82542C55.6664 9.74776 55.9564 10.8019 55.9564 11.9878C55.9564 13.1737 55.6664 14.2278 55.0864 15.1501C54.5065 16.0631 53.7014 16.7831 52.6714 17.3101C51.6415 17.8278 50.4514 18.0866 49.1014 18.0866ZM49.1014 14.5572C49.6214 14.5572 50.0714 14.4489 50.4514 14.2325C50.8315 14.016 51.1264 13.7101 51.3364 13.3148C51.5464 12.9195 51.6514 12.4725 51.6514 11.9737C51.6514 11.4654 51.5464 11.0137 51.3364 10.6184C51.1264 10.2231 50.8315 9.91248 50.4514 9.68659C50.0714 9.46071 49.6214 9.34777 49.1014 9.34777C48.5815 9.34777 48.1264 9.46071 47.7364 9.68659C47.3564 9.91248 47.0564 10.2231 46.8364 10.6184C46.6264 11.0137 46.5214 11.4654 46.5214 11.9737C46.5214 12.4725 46.6264 12.9195 46.8364 13.3148C47.0564 13.7101 47.3564 14.016 47.7364 14.2325C48.1264 14.4489 48.5815 14.5572 49.1014 14.5572Z"
          fill={darkMode ? "#ffffff" : "#206bc4"}
        />
        <path
          d="M22.5878 18.0866C21.2378 18.0866 20.0378 17.8278 18.9878 17.3101C17.9478 16.7831 17.1278 16.0631 16.5278 15.1501C15.9378 14.2278 15.6428 13.1737 15.6428 11.9878C15.6428 10.8019 15.9378 9.74776 16.5278 8.82542C17.1278 7.89365 17.9478 7.15954 18.9878 6.62307C20.0378 6.08659 21.2378 5.81836 22.5878 5.81836C23.9378 5.81836 25.1278 6.08659 26.1578 6.62307C27.1879 7.15954 27.9927 7.89365 28.5728 8.82542C29.1529 9.74776 29.4428 10.8019 29.4428 11.9878C29.4428 13.1737 29.1529 14.2278 28.5728 15.1501C27.9927 16.0631 27.1879 16.7831 26.1578 17.3101C25.1278 17.8278 23.9378 18.0866 22.5878 18.0866ZM22.5878 14.5572C23.1079 14.5572 23.5579 14.4489 23.9378 14.2325C24.3177 14.016 24.6128 13.7101 24.8228 13.3148C25.0328 12.9195 25.1378 12.4725 25.1378 11.9737C25.1378 11.4654 25.0328 11.0137 24.8228 10.6184C24.6128 10.2231 24.3177 9.91248 23.9378 9.68659C23.5579 9.46071 23.1079 9.34777 22.5878 9.34777C22.0678 9.34777 21.6128 9.46071 21.2228 9.68659C20.8428 9.91248 20.5428 10.2231 20.3228 10.6184C20.1128 11.0137 20.0078 11.4654 20.0078 11.9737C20.0078 12.4725 20.1128 12.9195 20.3228 13.3148C20.5428 13.7101 20.8428 14.016 21.2228 14.2325C21.6128 14.4489 22.0678 14.5572 22.5878 14.5572Z"
          fill={darkMode ? "#ffffff" : "#206bc4"}
        />
        <path
          d="M35.1428 18.1513H30.6428V0H35.1428V18.1513Z"
          fill={darkMode ? "#ffffff" : "#206bc4"}
        />
        <path
          d="M40.9286 18.1513H36.4286V0H40.9286V18.1513Z"
          fill={darkMode ? "#ffffff" : "#206bc4"}
        />
        <path
          d="M14.25 24H9.75V5.84875H14.25V24Z"
          fill={darkMode ? "#ffffff" : "#206bc4"}
        />
        <path
          d="M6.945 18.0866C5.595 18.0866 4.395 17.8278 3.345 17.3101C2.30501 16.7831 1.485 16.0631 0.885 15.1501C0.295001 14.2278 0 13.1737 0 11.9878C0 10.8019 0.295001 9.74776 0.885 8.82542C1.485 7.89365 2.30501 7.15954 3.345 6.62307C4.395 6.08659 5.595 5.81836 6.945 5.81836C8.295 5.81836 9.48499 6.08659 10.515 6.62307C11.545 7.15954 12.35 7.89365 12.93 8.82542C13.51 9.74776 13.8 10.8019 13.8 11.9878C13.8 13.1737 13.51 14.2278 12.93 15.1501C12.35 16.0631 11.545 16.7831 10.515 17.3101C9.48499 17.8278 8.295 18.0866 6.945 18.0866ZM6.945 14.5572C7.46501 14.5572 7.91501 14.4489 8.295 14.2325C8.67499 14.016 8.97 13.7101 9.18 13.3148C9.39 12.9195 9.495 12.4725 9.495 11.9737C9.495 11.4654 9.39 11.0137 9.18 10.6184C8.97 10.2231 8.67499 9.91248 8.295 9.68659C7.91501 9.46071 7.46501 9.34777 6.945 9.34777C6.42499 9.34777 5.97 9.46071 5.58 9.68659C5.20001 9.91248 4.90001 10.2231 4.68 10.6184C4.47 11.0137 4.365 11.4654 4.365 11.9737C4.365 12.4725 4.47 12.9195 4.68 13.3148C4.90001 13.7101 5.20001 14.016 5.58 14.2325C5.97 14.4489 6.42499 14.5572 6.945 14.5572Z"
          fill={darkMode ? "#ffffff" : "#206bc4"}
        />
        <path
          d="M57.8572 0L62.3572 3.70262e-07V18.1513H57.8572V0Z"
          fill={darkMode ? "#ffffff" : "#206bc4"}
        />
        <path
          d="M65.0549 5.91333C66.4049 5.91333 67.605 6.17215 68.655 6.6898C69.6949 7.21685 70.5149 7.93685 71.1149 8.8498C71.7049 9.77215 71.9999 10.8263 71.9999 12.0122C71.9999 13.198 71.7049 14.2522 71.1149 15.1745C70.5149 16.1063 69.6949 16.8404 68.655 17.3769C67.605 17.9133 66.4049 18.1816 65.0549 18.1816C63.7049 18.1816 62.515 17.9133 61.4849 17.3769C60.4549 16.8404 59.65 16.1063 59.07 15.1745C58.4899 14.2522 58.2 13.198 58.2 12.0122C58.2 10.8263 58.4899 9.77215 59.07 8.8498C59.65 7.93685 60.4549 7.21685 61.4849 6.6898C62.515 6.17215 63.7049 5.91333 65.0549 5.91333ZM65.0549 9.44274C64.5349 9.44274 64.0849 9.55098 63.705 9.76745C63.325 9.98391 63.03 10.2898 62.82 10.6851C62.61 11.0804 62.505 11.5275 62.505 12.0263C62.505 12.5345 62.61 12.9863 62.82 13.3816C63.03 13.7769 63.325 14.0874 63.705 14.3133C64.0849 14.5392 64.5349 14.6522 65.0549 14.6522C65.575 14.6522 66.0299 14.5392 66.4199 14.3133C66.7999 14.0874 67.0999 13.7769 67.32 13.3816C67.53 12.9863 67.635 12.5345 67.635 12.0263C67.635 11.5275 67.53 11.0804 67.32 10.6851C67.0999 10.2898 66.7999 9.98391 66.4199 9.76745C66.0299 9.55098 65.575 9.44274 65.0549 9.44274Z"
          fill={darkMode ? "#ffffff" : "#206bc4"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3500_15159">
          <rect
            width="72"
            height="24"
            fill={darkMode ? "#ffffff" : "#206bc4"}
          />
        </clipPath>
      </defs>
    </svg>
  );
}
