import { useNavigate } from "react-router-dom";
import { useSharedState } from "sharedStateProvider";
import {
  IconApi,
  IconApps,
  IconBellCheck,
  IconBuildingBridge2,
  IconCheck,
  IconEdit,
  IconFiles,
  IconLayout2,
  IconLayoutSidebar,
  IconLogout2,
  IconMessages,
  IconMoon,
  IconNotification,
  IconSun,
  IconUsers,
  IconVersions,
} from "@tabler/icons-react";
import LogoHeader from "assets/images/logo-header";

export default function VerticalNavbar() {
  const navigate = useNavigate();
  const { logout, darkMode, setDarkMode } = useSharedState();

  const changeTheme = () => {
    if (darkMode) {
      localStorage.setItem("tablerTheme", "light");
      setDarkMode(false);
    } else {
      localStorage.setItem("tablerTheme", "dark");
      setDarkMode(true);
    }
  };

  return (
    <aside
      className="navbar navbar-vertical navbar-expand-lg"
      data-bs-theme="dark"
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebar-menu"
          aria-controls="sidebar-menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <h1 className="navbar-brand navbar-brand-autodark">
          <LogoHeader size={1.5} />
        </h1>
        <div className="navbar-nav flex-row d-lg-none">
          <div className="d-flex">
            <div
              onClick={changeTheme}
              className="nav-link px-0 hide-theme-dark cursor-pointer"
            >
              <IconMoon className="icon" />
            </div>
            <div
              onClick={changeTheme}
              className="nav-link px-0 hide-theme-light cursor-pointer"
            >
              <IconSun className="icon" />
            </div>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="sidebar-menu">
          <ul className="navbar-nav">
            <li
              className={`nav-item cursor-pointer ${
                window.location.pathname === "/" && "active"
              }`}
              onClick={() => navigate("/")}
            >
              <div className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconUsers className="icon" />
                </span>
                <span className="nav-link-title">Пользователи</span>
              </div>
            </li>
            <li
              className={`nav-item cursor-pointer ${
                window.location.pathname === "/admins" && "active"
              }`}
              onClick={() => navigate("/admins")}
            >
              <div className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconUsers className="icon" />
                </span>
                <span className="nav-link-title">Администраторы</span>
              </div>
            </li>
            <li
              className={`nav-item cursor-pointer ${
                window.location.pathname === "/ap-calls" && "active"
              }`}
              onClick={() => navigate("/ap-calls")}
            >
              <div className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconApi className="icon" />
                </span>
                <span className="nav-link-title">Вызовы AP</span>
              </div>
            </li>
            <li
              className={`nav-item cursor-pointer ${
                window.location.pathname === "/versions" && "active"
              }`}
              onClick={() => navigate("/versions")}
            >
              <div className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconVersions className="icon" />
                </span>
                <span className="nav-link-title">Версии приложения</span>
              </div>
            </li>
            <li
              className={`nav-item cursor-pointer ${
                window.location.pathname === "/mini-apps" && "active"
              }`}
              onClick={() => navigate("/mini-apps")}
            >
              <div className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconApps className="icon" />
                </span>
                <span className="nav-link-title">Мини-приложения</span>
              </div>
            </li>
            <li
              className={`nav-item cursor-pointer ${
                window.location.pathname === "/moderation" && "active"
              }`}
              onClick={() => navigate("/moderation")}
            >
              <div className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconEdit className="icon" />
                </span>
                <span className="nav-link-title">Модерация</span>
              </div>
            </li>
            <li
              className={`nav-item cursor-pointer ${
                window.location.pathname === "/bridge-methods" && "active"
              }`}
              onClick={() => navigate("/bridge-methods")}
            >
              <div className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconBuildingBridge2 className="icon" />
                </span>
                <span className="nav-link-title">Методы бриджа</span>
              </div>
            </li>
            <li
              className={`nav-item cursor-pointer ${
                window.location.pathname === "/enabled-notifications" &&
                "active"
              }`}
              onClick={() => navigate("/enabled-notifications")}
            >
              <div className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconBellCheck className="icon" />
                </span>
                <span className="nav-link-title">Разрешённые уведомления</span>
              </div>
            </li>
            <li
              className={`nav-item cursor-pointer ${
                window.location.pathname === "/notifications" && "active"
              }`}
              onClick={() => navigate("/notifications")}
            >
              <div className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconNotification className="icon" />
                </span>
                <span className="nav-link-title">Уведомления</span>
              </div>
            </li>
            {/* <li
              className={`nav-item cursor-pointer ${
                window.location.pathname === "/files" && "active"
              }`}
              onClick={() => navigate("/files")}
            >
              <div className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconFiles className="icon" />
                </span>
                <span className="nav-link-title">Файлы</span>
              </div>
            </li> */}
            <li
              className={`nav-item cursor-pointer ${
                window.location.pathname.includes("/chats") && "active"
              }`}
              onClick={() => navigate("/chats")}
            >
              <div className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconMessages className="icon" />
                </span>
                <span className="nav-link-title">Чаты</span>
              </div>
            </li>
            <li className="nav-item dropdown">
              <div
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                role="button"
                aria-expanded="true"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconLayout2 className="icon" />
                </span>
                <span className="nav-link-title">Меню</span>
              </div>
              <div className="dropdown-menu">
                <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column">
                    <div className="dropdown-item active">
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <IconLayoutSidebar className="icon" />
                      </span>
                      <span className="nav-link-title">Вертикальное</span>
                      <span className="badge badge-sm bg-green-lt text-uppercase ms-auto">
                        <IconCheck className="icon" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li
              className="nav-item d-except-sm cursor-pointer"
              onClick={changeTheme}
            >
              <div className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block px-0 hide-theme-dark">
                  <IconMoon className="icon" />
                </span>
                <span className="nav-link-icon d-md-none d-lg-inline-block px-0 hide-theme-light">
                  <IconSun className="icon" />
                </span>
                <span className="nav-link-title">Сменить тему</span>
              </div>
            </li>
            <li className="nav-item cursor-pointer" onClick={logout}>
              <div className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconLogout2 className="icon" color="#d93939" />
                </span>
                <span className="nav-link-title text-danger">
                  Выйти из системы
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
}
