export type PaginationType = {
  data: any[];
  page: number;
  size: number;
  sort: string;
  total: number;
};

export const emptyPagination: PaginationType = {
  data: [],
  page: 0,
  size: 0,
  sort: "",
  total: 0,
};
