import { IconAlertTriangle } from "@tabler/icons-react";

export default function DangerModal({
  dangerDescription,
  confirm,
}: {
  dangerDescription: string;
  confirm: () => void;
}) {
  return (
    <div
      className="modal modal-blur fade"
      id="modal-danger"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-sm modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div className="modal-status bg-danger"></div>
          <div className="modal-body text-center py-4">
            <IconAlertTriangle className="icon mb-2 text-danger icon-lg" />
            <h3>Подтвердите действие</h3>
            <div className="text-muted">{dangerDescription}</div>
          </div>
          <div className="modal-footer">
            <div className="w-100">
              <div className="row">
                <div className="col">
                  <a href="#" className="btn w-100" data-bs-dismiss="modal">
                    Отмена
                  </a>
                </div>
                <div className="col">
                  <a
                    href="#"
                    className="btn btn-danger w-100"
                    data-bs-dismiss="modal"
                    onClick={confirm}
                  >
                    Подтвердить
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
