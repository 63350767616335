import { Api } from "api";

export const getCollaborators: any = async (appId: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/mini_apps/v1/${appId}/collaborators`, {
    headers,
  });
  return res.data;
};

export const addCollaborators: any = async (appId: string, payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.post(`/mini_apps/v1/${appId}/collaborators`, payload, {
    headers,
  });
  return res.data;
};

export const deleteCollaborators: any = async (appId: string, payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.delete(`/mini_apps/v1/${appId}/collaborators`, {
    data: payload,
    headers,
  });
  return res.data;
};

export const findUser: any = async (phone: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/users/v1?phone_number=${phone}`, { headers });
  return res.data;
};
