import { UserAituType } from "types/user-types";

export type MiniAppFormType = {
  nameRu: string;
  nameKz: string;
  nameEn: string;
  descriptionRu: string;
  descriptionKz: string;
  descriptionEn: string;
  bridgeMethods: string[];
  link: string;
  logo: string;
  logo_url: string;
};

export type MiniAppType = {
  id: string;
  mode: string;
  names: {
    en: string;
    kz: string;
    ru: string;
  };
  descriptions: {
    en: string;
    kz: string;
    ru: string;
  };
  bridge_methods: string[];
  logo: string;
  logo_url: string;
  link: string;
  global: boolean;
  user?: UserAituType;
};

export type MiniAppsResponseType = {
  data: MiniAppType[];
  page: number;
  size: number;
  sort: string;
  total: number;
};

export type BridgeMethodType = {
  id?: string;
  name: string;
  description: string;
};

export type BridgeMethodResponseType = {
  data: BridgeMethodType[];
  page: number;
  size: number;
  sort: string;
  total: number;
};

export const emptyApp: MiniAppType = {
  id: "",
  mode: "",
  names: {
    en: "",
    kz: "",
    ru: "",
  },
  descriptions: {
    en: "",
    kz: "",
    ru: "",
  },
  bridge_methods: [],
  logo: "",
  logo_url: "",
  link: "",
  global: false,
};

export const emptyAppForm: MiniAppFormType = {
  nameRu: "",
  nameKz: "",
  nameEn: "",
  descriptionRu: "",
  descriptionKz: "",
  descriptionEn: "",
  bridgeMethods: [],
  link: "",
  logo: "",
  logo_url: "https://media-cdn.aitu.io/api/v2/media/download/avatar/013bc1b6-e5c2-11ee-9753-6e4e2a6ecf16",
};

export type MiniAppPayloadType = {
  names: {
    ru: string;
    kz: string;
    en: string;
  };
  descriptions: {
    ru: string;
    kz: string;
    en: string;
  };
  bridge_methods: string[];
  link: string;
  logo: string;
};

export const emptyBridgeMethod: BridgeMethodType = {
  id: "",
  name: "",
  description: "",
};
