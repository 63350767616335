import { useEffect, useState } from "react";
import { useSharedState } from "sharedStateProvider";
import { APCallsResponseType, APCallsRangeNames, APCallsFiltersList } from "types/ap-calls-type";
import { emptyPagination } from "types/pagination-types";
import PageLayout from "components/page-layout";
import Table, { tableHeader } from "components/tables/table";
import { oneLine } from "components/tables/table-parts";
import { getAPCalls } from "api/ap";

export default function APCalls() {
  const { notify } = useSharedState();

  const [APCalls, setAPCalls] = useState<APCallsResponseType>(emptyPagination);
  const [firstEntry, setFirstEntry] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());

  const updateFirstEntry = (value: number) => {
    setFirstEntry(value);
  };

  const arrayFromRequestBody = (body: string) => {
    const params: URLSearchParams = new URLSearchParams(body);
    const paramsArray: string[] = [];

    for (const [key, value] of params) {
      paramsArray.push(`${key}: ${value}`);
    }
    return paramsArray;
  };

  const getAndSetAPCalls = async (page?: number) => {
    setIsLoading(true);
    setAPCalls(emptyPagination);
    getAPCalls(searchParams, page)
      .then((res: APCallsResponseType) => {
        setAPCalls(res);
        setIsLoading(false);
      })
      .catch((error: any) => {
        let err = error.response.data.error;
        notify(err, "danger", "Ошибка при получении вызовов Aitu Passport");
      });
  };

  const appendParam = (key: string, value: string) => {
    if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  };

  const clearFilters = async () => {
    searchParams.delete("response_code");
    searchParams.delete("search");
    searchParams.delete("from");
    searchParams.delete("to");
  };

  useEffect(() => {
    getAndSetAPCalls();
  }, []);

  const APCallsTableHeaders: tableHeader[] = [
    { header_text: "№", narrow: true, wrap: true },
    { header_text: "ID", narrow: true, wrap: true },
    { header_text: "AP Sub", narrow: true, wrap: true },
    { header_text: "Scope", narrow: true, wrap: true },
    { header_text: "Target", narrow: true, wrap: true },
    { header_text: "Request URL", narrow: true, wrap: true },
    { header_text: "Request body", narrow: true, wrap: true },
    { header_text: "Response code", narrow: true, wrap: true },
    { header_text: "Created at", narrow: true, wrap: true },
  ];

  const APCallsList = (
    <>
      {APCalls.data.map((call, index) => (
        <tr key={index}>
          <td>{oneLine((firstEntry + index).toString())}</td>
          <td>{oneLine(call.id.toString() || "-")}</td>
          <td>{oneLine(call.ap_sub || "-")}</td>
          <td>{oneLine(call.scope || "-")}</td>
          <td>{oneLine(call.target || "-")}</td>
          <td>{oneLine(call.request_url || "-")}</td>
          <td>
            {arrayFromRequestBody(call.request_body).map((entry, index) => (
              <div key={index} style={{ maxWidth: 400 }}>
                {entry}
              </div>
            ))}
          </td>
          <td>{oneLine(call.response_code.toString() || "-")}</td>
          <td>
            {oneLine(`${new Date(call.created_at).toLocaleString()}` || "-")}
          </td>
        </tr>
      ))}
    </>
  );

  const APCallsTable = Table(
    APCallsTableHeaders,
    APCallsList,
    true,
    APCalls,
    updateFirstEntry,
    getAndSetAPCalls
  );

  const APCallsHeader = "Вызовы Aitu Passport";
  const APCallsSubheaderText = "Количество вызовов Aitu Passport: ";
  const APCallsSubheaderCount = APCalls.total;

  return (
    <PageLayout
      children={APCallsTable}
      isLoading={isLoading}
      filters={true}
      filtersList={APCallsFiltersList}
      appendFilter={appendParam}
      clearFilters={clearFilters}
      search={true}
      searchAPI={getAndSetAPCalls}
      header={APCallsHeader}
      subheaderText={APCallsSubheaderText}
      subheaderCount={APCallsSubheaderCount}
      dataLength={APCalls.data.length}
      dateRange={true}
      rangeNames={APCallsRangeNames}
    />
  );
}
