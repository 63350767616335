import { FiltersList } from "types/page-layout-types";

export type UserType = {
  id: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  avatar: string;
  avatar_thumb: string;
  phone: string;
  iin: string;
  birth_date: string;
  gender: string;
  ap_id?: string;
  status: string;
  user_type: string;
  enterprise: string;
  org_unit: string;
  position: string;
  email?: string;
  password?: string;
  updated_at: string;
  created_at: string;
  became_admin_at?: string;
  last_logged_in?: string;
};

export type UsersResponseType = {
  data: UserType[];
  page: number;
  size: number;
  sort: string;
  total: number;
};

export const UserTypeDict = [
  "employee",
  "role_contractor",
  "vendor",
  "student",
  "family",
  "anonymous",
];

export const emptyUser: UserType = {
  id: "",
  first_name: "",
  last_name: "",
  middle_name: "",
  avatar: "",
  avatar_thumb: "",
  phone: "",
  iin: "",
  birth_date: "",
  gender: "",
  ap_id: "",
  status: "active",
  user_type: "employee",
  enterprise: "",
  org_unit: "",
  position: "",
  updated_at: "",
  created_at: "",
};

export type MeType = {
  id: string;
  name: string;
  lastname: string;
  avatar: string;
  avatarThumb: string;
  phone: string;
  iin: string;
  status: string;
  user_type: string;
};

export const emptyMe = {
  id: "",
  name: "",
  lastname: "",
  avatar: "",
  avatarThumb: "",
  phone: "",
  iin: "",
  status: "",
  user_type: "",
};

export type UserAituType = {
  id: string;
  name: string;
  lastname: string;
  avatar: string;
  avatarThumb: string;
  phone: string;
  iin: string;
  status: string;
  user_type: string;
  sign: string;
};

export type UserSession = {
  id: string;
  device: {
    id: string;
    name: string;
    os: string;
  };
  token: {
    access: {
      expires_at: string;
      hash: string;
      issue_date: string;
      ttl: number;
    };
    refresh: {
      expires_at: string;
      hash: string;
      issue_date: string;
      ttl: number;
    };
  };
  created_at: string;
};

export const userFiltersList: FiltersList = {
  type: [
    {
      name: "Тип",
      value: "",
      filter_name: "type",
      disabled: true,
    },
    {
      name: "employee",
      value: "employee",
      filter_name: "type",
      disabled: false,
    },
    {
      name: "role_contractor",
      value: "role_contractor",
      filter_name: "type",
      disabled: false,
    },
    {
      name: "vendor",
      value: "vendor",
      filter_name: "type",
      disabled: false,
    },
  ],
  status: [
    {
      name: "Статус",
      value: "",
      filter_name: "status",
      disabled: true,
    },
    {
      name: "active",
      value: "active",
      filter_name: "status",
      disabled: false,
    },
    {
      name: "inactive",
      value: "inactive",
      filter_name: "status",
      disabled: false,
    },
  ],
};
