export default function formatDate(date: Date) {
  // Extract year, month, and day
  const year: number | string = date.getFullYear();
  let month: number | string = date.getMonth() + 1; // getMonth() returns 0-based index
  let day: number | string = date.getDate();

  // Ensure month and day are formatted to have two digits
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }

  // Return formatted date
  return `${year}-${month}-${day}`;
}
