import { Api } from "api";

export const getFiles = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get("/files/v1/admin", { headers });
  return res.data;
};

export const getFileLink = async (fileId: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/files/v1/admin?id=${fileId}`, { headers });
  return res.data;
};
