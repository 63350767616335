import { useState } from "react";

export default function RefuseReasonModal({ submitRefuse }: { submitRefuse: (reason: string) => void }) {
  const [reasonText, setReasonText] = useState<string>("");

  const handleReasonText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    setReasonText(inputValue);
  };

  const cancelRefuse = () => {
    let allInputs = document.querySelectorAll("textarea");
    allInputs.forEach((singleInput) => {
      singleInput.value = "";
    });
    setReasonText("");
  };

  return (
    <div className="modal modal-blur fade" id="modal-refuse" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Отклонение обновления</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={cancelRefuse}
            ></button>
          </div>
          <div className="modal-body">
            <div className="col-12">
              <div className="mb-3">
                <textarea
                  className="form-control d-inline-block w-9"
                  placeholder="Укажите причину отклонения"
                  onChange={handleReasonText}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="btn btn-link link-secondary" data-bs-dismiss="modal" onClick={cancelRefuse}>
              Отмена
            </div>
            <button
              className="btn btn-primary ms-auto"
              data-bs-dismiss="modal"
              onClick={() => {
                submitRefuse(reasonText);
                cancelRefuse();
              }}
              disabled={reasonText === ""}
            >
              Подтвердить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
