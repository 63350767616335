import { useState, useMemo } from "react";
import InputMask from "react-input-mask";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useSharedState } from "sharedStateProvider";
import LogoHeader from "assets/images/logo-header";
import RemoveNonNumericCharacters from "components/helpers/remove-non-numeric";

export default function SignIn() {
  const { login } = useSharedState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedPhoneNumber = RemoveNonNumericCharacters(event.target.value);
    setPhone(cleanedPhoneNumber);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const authorize = async (e: any) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      await login(phone, password);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const isFormValid: boolean = useMemo(() => {
    if (phone.length < 11 || !password) {
      return true;
    } else return false;
  }, [phone, password]);

  return (
    <div className="page page-center">
      <div className="container container-tight py-4">
        <div className="text-center mb-4">
          <div className="navbar-brand navbar-brand-autodark">
            <LogoHeader size={2} />
          </div>
        </div>
        <div className="card card-md">
          <div className="card-body">
            <h2 className="h2 text-center mb-4">
              Войти в панель администратора
            </h2>
            <form noValidate>
              <div className="mb-3">
                <label className="form-label">Номер телефона</label>
                <InputMask
                  className="form-control"
                  type="tel"
                  placeholder="+7-(___)-___-__-__"
                  mask="+7-(999)-999-99-99"
                  maskChar="_"
                  value={phone}
                  onChange={onPhoneChange}
                  readOnly={isLoading}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Пароль</label>
                <div className="input-group input-group-flat">
                  <input
                    className="form-control"
                    type={showPassword ? "text" : "password"}
                    placeholder="Введите пароль"
                    onChange={onPasswordChange}
                    readOnly={isLoading}
                  />
                  <span className="input-group-text">
                    <div
                      className="link-secondary cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <IconEyeOff className="icon" />
                      ) : (
                        <IconEye className="icon" />
                      )}
                    </div>
                  </span>
                </div>
              </div>
              <div className="form-footer">
                <button
                  className="btn btn-primary w-100"
                  onClick={authorize}
                  disabled={isFormValid || isLoading}
                >
                  {isLoading ? (
                    <div
                      className="spinner-border spinner-border-md"
                      role="status"
                    />
                  ) : (
                    <div>Войти</div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
