import { useSharedState } from "sharedStateProvider";
import VerticalNavbar from "./vertical";
import HorizontalNavbar from "./horizontal";

const NavbarContainer = () => {
  const {} = useSharedState();

  let componentToRender;
  let value = "vertical";

  switch (value) {
    case "vertical":
      componentToRender = <VerticalNavbar />;
      break;
    default:
      componentToRender = <HorizontalNavbar />;
      break;
  }

  return <>{componentToRender}</>;
};

export default NavbarContainer;
