import LogoHeader from "assets/images/logo-header";

export default function PageLoader() {
  return (
    <div className="page page-center">
      <div className="container container-slim py-4">
        <div className="text-center">
          <div className="mb-3">
            <div className="navbar-brand navbar-brand-autodark">
              <LogoHeader size={2} />
            </div>
          </div>
          <div className="text-muted mb-3">Загрузка данных</div>
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
