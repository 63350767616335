import { IconUser, IconApps, IconCheck, IconX } from "@tabler/icons-react";

export const dynamicColor = (color?: string) => {
  return color ? `text-${color}` : undefined;
};

export const avatarWithTwoLines = (avatar: string, avatar_style: "user" | "app", line_1: string, line_2: string) => {
  const emptyAvatar = () => {
    switch (avatar_style) {
      case "user":
        return <IconUser />;
      case "app":
        return <IconApps />;
      default:
        break;
    }
  };

  return (
    <div className="d-flex py-1 align-items-center">
      <span
        className="avatar me-2"
        style={
          avatar
            ? {
                backgroundImage: `url(${avatar})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
              }
            : undefined
        }
      >
        {!avatar && emptyAvatar()}
      </span>
      <div className="flex-fill">{twoLines(line_1, line_2)}</div>
    </div>
  );
};

export const twoLines = (line_1: string, line_2: string) => {
  return (
    <>
      {oneLine(line_1)}
      {mutedLine(line_2)}
    </>
  );
};

export const oneLine = (line: string, color?: string) => {
  return <div className={dynamicColor(color)}>{line}</div>;
};

export const mutedLine = (line: string) => {
  return <div className="text-muted">{line}</div>;
};

export const linkLine = (link: string) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {link}
    </a>
  );
};

export const checkOrXIcon = (value: boolean) => {
  return value ? <IconCheck className="icon text-success" /> : <IconX className="icon text-danger" />;
};

export const actionButton = (action: () => void, icon: React.JSX.Element | undefined, color: string, text?: string) => {
  return (
    <button className={`btn ${dynamicColor(color)}`} onClick={action}>
      {icon}
      {text}
    </button>
  );
};
